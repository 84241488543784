import React, { useState } from "react";
import { Container, Card, CardBody, Label, Input } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Row, Col } from "reactstrap";
import { addCustomCurrency } from "Services/Api";
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const CustomCurrency = () => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [exchangeRate, setExchangeRate] = useState<any>();
  document.title = t("Custom Currencies");
  const navigate = useNavigate();



  const submit = async () => {

    try {
      const response = await addCustomCurrency(name, code, exchangeRate);

      if (response.status == 'error') {
        toast.error(response.message, {
          autoClose: 2000,
        });
        return false;
      }
      toast.success('Custom currency added successfully');
      setTimeout(() => {
        navigate('/currencies');
      }, 1000);
    } catch (error: any) {

      console.error('Error adding custom currency:', error);



      toast.error('Failed to add custom currency');
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Custom Currencies")} pageTitle={t("Currencies")} />
          <div>
              <Row className="align-items-center">
                <Col lg={12}>
                  <div className="d-flex justify-content-end align-item-center">

                    <Link to="/currencies"><button className="btn btn-primary"> <i className=" ri-arrow-left-line"></i>{t('Back')}</button></Link>

                  </div>
                </Col>
              </Row>
            </div>
          <div className="d-flex align-items-end justify-content-center mt-3">
            <Col md={12} lg={12} xxl={12} xl={12} >
              <Card>
                <CardBody>
                  <strong> <h4 className='text-black'>{t('Create Currency')}</h4></strong>
                  <Row className="mt-5 mb-3">
                    <Col lg={2}>
                      <Label htmlFor="nameInput" className="form-label mt-2">
                        {t('Name')}
                      </Label>
                    </Col>
                    <Col lg={8}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameInput"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={t("Enter Name")}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-5 mb-3">
                    <Col lg={2}>
                      <Label htmlFor="nameInput" className="form-label mt-2">
                       {t('Code')}
                      </Label>
                    </Col>
                    <Col lg={8}>
                      <Input
                        type="text"
                        className="form-control"
                        id="nameInput"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder={t("Enter Code")}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-5 mb-3">
                    <Col lg={2}>
                      <Label htmlFor="nameInput" className="form-label mt-2">
                       {t('Exchange Rate')}
                      </Label>
                    </Col>
                    <Col lg={8}>
                      <Input
                        type="number"
                        className="form-control"
                        id="nameInput"
                        value={exchangeRate}
                        onChange={(e) => setExchangeRate(e.target.value)}
                        placeholder={t("Enter Exchange Rate")}
                      />
                    </Col>
                  </Row>
                  <div className="text-end ">
                    <button className="btn btn-success" onClick={() => { submit() }}>{t('Submit')}</button>
                    <span style={{ marginRight: '10px' }}></span>
                    <Link to="/currencies"><button className="btn btn-danger">{('Cancel')}</button></Link>
                  </div>

                </CardBody>

              </Card>

            </Col>

          </div>



        </Container>

      </div>

    </React.Fragment>
  );
};

export default CustomCurrency;
