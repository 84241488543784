import BreadCrumb from "Components/Common/BreadCrumb";
import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { Link, useNavigate } from "react-router-dom";
import { Container, Table } from "reactstrap"
import FeatherIcon from "feather-icons-react";
import { fetchSubAdmin, AdminData } from "Services/Api";
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';

const AddSubAdmin = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [subAdmins, setSubAdmins] = useState<AdminData[]>([]);

  useEffect(() => {
    const fetchSubAdminData = async () => {
      try {
        setLoading(true);
        const res = await fetchSubAdmin();
        if (res && res.data) {
          const adminDataArray = res.data?.adminData || [];
          setSubAdmins(adminDataArray);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        toast.error('Failed to fetch subadmin data');
      }
    };

    fetchSubAdminData();
  }, []);


  const navigate = useNavigate();
  const openModel = () => {
    navigate('/create-new-subadmin')
  }

  document.title = t('Add SubAdmin')

  return (
    <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Add SubAdmin")} pageTitle={t("Subadmin")} />
            <div className="d-flex align-items-end justify-content-end mb-4">
              <button onClick={openModel} className="btn btn-primary">{t('Add SubAdmin')}</button>
            </div>
            <div className="table-responsive">
              <Table className="table-striped table-nowrap align-middle mb-0">
                <thead>
                  <tr className="text-center">
                    <th scope="col">{t('First Name')}</th>
                    <th scope="col">{t('Last Name')}</th>
                    <th scope="col">{t('Email')}</th>
                    <th scope="col">{t('Action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    Array.from({ length: subAdmins.length || 3 }).map((_, index) => (
                      <tr key={index} className="text-center">
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                      </tr>
                    ))
                  ) : (
                    subAdmins && subAdmins.length > 0 ? (
                      subAdmins.map((provider, index) => (
                        <tr className="text-center" key={index}>
                          <td>{provider?.first_name}</td>
                          <td>{provider?.last_name}</td>
                          <td>{provider?.email}</td>
                          <td>
                            <span
                              className={`rounded p-1 text-white `}
                              style={{ cursor: 'pointer' }}
                            >
                            </span>
                            <Link to={`/edit-sub-admin/${provider?.id}`}>
                              <FeatherIcon icon="edit-2" />
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr></tr>
                    )
                  )}
                </tbody>
              </Table>
              {<h4 style={{ textAlign: "center", color: "grey", marginTop: "5rem" }}>{subAdmins.length === 0 && !loading ? <> {"There is no subadmins at the moment"}</> : <></>}</h4>}
            </div>
          </Container>
        </div>
      </React.Fragment>
    </SkeletonTheme>
  )
}

export default AddSubAdmin