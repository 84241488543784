import React, { useState, useEffect, useRef } from "react";
import { PermissionHelper } from 'Routes/helper';
import { Card, CardBody, Col, Row, Button } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import Flatpickr from "react-flatpickr";
import { fetchDashboardReport, fetchOperatorTenants, Tenant, DashboardReportFilter } from "Services/Api";
import { useTranslation } from 'react-i18next';


interface TopOperator {
    id: number;
    operatorname: string;
    user_count: number;
}
interface BestOperators {

    operatorname: string;
    ggr_percent: any;
}
interface topOperatorsLosses {
    operatorname: string;
    losses: any;
}
interface topOperatorsBets {
    operatorname: string;
    total_bets: number;
}
interface topOperatorsWins {
    operatorname: string;
    total_wins: number;
}
interface totalGGRandBetsWins {
    total_ggr: any;
    total_bets: any;
    total_wins: any
}
interface ggrOfUsers {
    username: string;
    ggr: any;
}
interface mostGamesByPlayer {
    username: string;
    games: number;
}
interface mostPlayedGameProvider {
    vendor_name: string;
    game_providers: number
}
interface mostProfitableProviders {
    vendor_name: string;
    total_profit: number;
}
interface mostProfitableBrands {
    platform: string;
    total_profit: number;
}
interface mostProfitableGames {
    game_name: string;
    total_profit: number;
}
interface mostPlayedBrands {
    platform: string;
}
interface mostPlayedGames {
    game_name: string;
}

const Widgets = () => {

    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState('all');
    const [selectedDates, setSelectedDates] = useState<Date[]>([new Date()]);
    const flatpickrRef = useRef<Flatpickr>();
    const [tenants, setTenants] = useState<Tenant[]>([]);
    document.title = t('Dashboard');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [rightColumn, setRightColumn] = useState<boolean>(true);

    const [topOperator, setTopOperator] = useState<TopOperator[]>([]);
    const [bestOperators, setBestOperators] = useState<BestOperators[]>([]);
    const [topOperatorsLosses, setTopOperatorsLosses] = useState<topOperatorsLosses[]>([]);
    const [topOperatorsBets, setTopOperatorsBets] = useState<topOperatorsBets[]>([]);
    const [topOperatorsWins, setTopOperatorsWins] = useState<topOperatorsWins[]>([]);
    const [totalGGRandBetsWins, setTotalGGRandBetsWins] = useState<totalGGRandBetsWins>();
    const [ggrOfUsers, setGgrOfUsers] = useState<ggrOfUsers[]>([]);
    const [mostGamesByPlayer, setMostGamesByPlayer] = useState<mostGamesByPlayer[]>([]);
    const [mostPlayedGameProvider, setMostPlayedGameProvider] = useState<mostPlayedGameProvider[]>([]);
    const [mostProfitableProviders, setMostProfitableProviders] = useState<mostProfitableProviders[]>([]);
    const [mostPlayedBrands, setMostPlayedBrands] = useState<mostPlayedBrands[]>([]);
    const [mostProfitableBrands, setMostProfitableBrands] = useState<mostProfitableBrands[]>([]);
    const [mostPlayedGames, setMostPlayedGames] = useState<mostPlayedGames[]>([]);
    const [mostProfitableGames, setMostProfitableGames] = useState<mostProfitableGames[]>([]);

    const toggleRightColumn = () => {
        setRightColumn(!rightColumn);
    };
    const handleDateSelection = (selected: Date[]) => {
        setSelectedDates(selected);
    };
    let Adminrole: string | null = null;
    if (!Adminrole) {
        const authUserstring = sessionStorage.getItem('authUser');
        if (authUserstring) {
            const authUser = JSON.parse(authUserstring);
            if (authUser && authUser.data.role) {
                Adminrole = authUser.data.role;
            }
        }
    }
    const adjustDateForTimezone = (date: Date) => {
        const timezoneOffsetMinutes = date.getTimezoneOffset();
        return new Date(date.getTime() - timezoneOffsetMinutes * 60 * 1000);
    };
    const handleButtonClick = (range: string) => {
        let start: Date | string = new Date();
        let end: Date | string = new Date();

        switch (range) {
            case 'today':
                start = end = new Date();
                break;
            case 'week':
                start = new Date();
                end = new Date();
                end.setDate(end.getDate() + 6);
                break;
            case 'month':
                start = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                end = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
                break;
            case 'year':
                start = new Date(new Date().getFullYear(), 0, 1);
                end = new Date(new Date().getFullYear(), 11, 31);
                break;
            default:
                break;
        }

        const adjustedStartDate = adjustDateForTimezone(start);
        const adjustedEndDate = adjustDateForTimezone(end);

        setSelectedDates([adjustedStartDate, adjustedEndDate]);
        updateFilteredData(selectedValue, [adjustedStartDate, adjustedEndDate]);
    };
    const updateFilteredData = async (operatorId: any, selectedDates: any) => {

        try {

            const adjustedStartDate = adjustDateForTimezone(selectedDates[0]);
            const adjustedEndDate = adjustDateForTimezone(selectedDates[1]);

            const params = {
                operator_id: operatorId,
                selectedDate: {
                    startDate: adjustedStartDate,
                    endDate: adjustedEndDate
                }
            };


            const reportResponse = await DashboardReportFilter(operatorId, params.selectedDate);


            setTopOperator(reportResponse.TopOperator);
            setBestOperators(reportResponse.bestOperators);
            setTopOperatorsLosses(reportResponse.topOperatorsLosses);
            setTopOperatorsBets(reportResponse.topOperatorsBets);
            setTopOperatorsWins(reportResponse.topOperatorsWins);
            setTotalGGRandBetsWins(reportResponse.totalGGRandBetsWins);
            setGgrOfUsers(reportResponse.ggrOfUsers);
            setMostGamesByPlayer(reportResponse.mostGamesByPlayer);
            setMostPlayedGameProvider(reportResponse.mostPlayedGameProvider);
            setMostProfitableProviders(reportResponse.mostProfitableProviders);
            setMostPlayedBrands(reportResponse.topMostPlayedBrands);
            setMostProfitableBrands(reportResponse.topMostProfitableBrands);
            setMostPlayedGames(reportResponse.topMostPlayedGames);
            setMostProfitableGames(reportResponse.topMostProfitableGames);



            const tenantResponse = await fetchOperatorTenants();
            if (tenantResponse && tenantResponse.data && Array.isArray(tenantResponse.data)) {
                setTenants(tenantResponse.data);
            } else {
                console.error('Invalid tenant data format received:', tenantResponse);
            }


            const permission = PermissionHelper.PermissionChecker("operator_tenants.view");
            if (!permission) {
                toast.error("You don't have permission to view this page");
            }
        } catch (error) {
            console.error('Error fetching data:', error);

            if (error === 'Request failed with status code 401') {
                navigate('/login');
                toast.error("Token expired. Please login again.");
            } else {

            }
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async () => {
        try {
            const apiResponse = await fetchOperatorTenants();

            if (apiResponse && apiResponse.data && Array.isArray(apiResponse.data)) {
                setTenants(apiResponse.data);

            } else {
                console.error('Invalid data format received:', apiResponse);
            }
            const permission = PermissionHelper.PermissionChecker("operator_tenants.view");
            if (permission) {
                const apiResponse = await fetchDashboardReport();

                if (apiResponse.TopOperator && apiResponse.TopOperator && Array.isArray(apiResponse.TopOperator)) {

                    setTopOperator(apiResponse.TopOperator);
                    setBestOperators(apiResponse.bestOperators);
                    setTopOperatorsLosses(apiResponse.topOperatorsLosses);
                    setTopOperatorsBets(apiResponse.topOperatorsBets);
                    setTopOperatorsWins(apiResponse.topOperatorsWins);
                    setTotalGGRandBetsWins(apiResponse.totalGGRandBetsWins);
                    setGgrOfUsers(apiResponse.ggrOfUsers);
                    setMostGamesByPlayer(apiResponse.mostGamesByPlayer);
                    setMostPlayedGameProvider(apiResponse.mostPlayedGameProvider);
                    setMostProfitableProviders(apiResponse.mostProfitableProviders);
                    setMostPlayedBrands(apiResponse.topMostPlayedBrands);
                    setMostProfitableBrands(apiResponse.topMostProfitableBrands);
                    setMostPlayedGames(apiResponse.topMostPlayedGames);
                    setMostProfitableGames(apiResponse.topMostProfitableGames);



                } else {
                    console.error('Invalid data format received:', apiResponse.TopOperator);
                }
            } else {
                toast.error("You don't have permission to view this page");
            }





        } catch (error: any) {
            console.error('Error fetching data:', error);

            if (error === 'Request failed with status code 401') {
                navigate('/login');
                toast.error("Token expired. Please login again.")


            } else {

            }
        } finally {
            setLoading(false);
        }
    };
    const handleRefreshClick = () => {
        const today = new Date();
        setSelectedDates([today, today]);
        updateFilteredData(selectedValue, [today, today]);
    };
    useEffect(() => {
        if (flatpickrRef.current) {
            flatpickrRef.current.flatpickr.setDate(selectedDates);
        }

        fetchData();
    }, []);
    const handleDropdownChange = (value: any) => {
        setSelectedValue(value);
        const today = new Date();
        let startDate = selectedDates && selectedDates[0] ? selectedDates[0] : today;
        let endDate = selectedDates && selectedDates[1] ? selectedDates[1] : today;
        updateFilteredData(value, [startDate, endDate]);
    };


    return (
        <React.Fragment>
            <Row className="mb-3 pb-1">
                <Col xs={12}>
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                        <div className="flex-grow-1">
                            <Row className="align-items-center">
                                <Col lg={8}>
                                    <select
                                        value={selectedValue}
                                        onChange={(e) => handleDropdownChange(e.target.value)}
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                    >
                                        <option value="all">{t('All Tenants')}</option>
                                        {tenants.map((tenant) => (
                                            <option key={tenant.id} value={tenant.id}>
                                                {tenant.operatorname}
                                            </option>
                                        ))}
                                    </select>
                                </Col>


                            </Row>
                        </div>

                        <Row className="mt-2">
                            <Col xs={12} className="d-flex flex-wrap justify-content-start">
                                <Button className="btn btn-success mb-2 me-2" onClick={() => handleButtonClick('today')}>{t('Today')}</Button>
                                <Button className="btn btn-warning mb-2 me-2" onClick={() => handleButtonClick('week')}>{t('Week')}</Button>
                                <Button className="btn btn-primary mb-2 me-2" onClick={() => handleButtonClick('month')}>{t('Month')}</Button>
                                <Button className="btn btn-info mb-2 me-2" onClick={() => handleButtonClick('year')}>{t('Year')}</Button>
                                <Button color='primary' className="mb-2 me-2" onClick={handleRefreshClick}>{t('Refresh')}</Button>
                            </Col>
                        </Row>


                        <div className="mt-3 mt-lg-0">
                            <form action="#">
                                <Row className="g-3 mb-0 align-items-center">
                                    <div className="col-sm-auto">
                                        <div className="input-group">
                                            <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d M, Y",
                                                    defaultDate: selectedDates,

                                                }}
                                                value={selectedDates}
                                                onChange={(dates: Date[]) => {
                                                    setSelectedDates(dates);
                                                    updateFilteredData(selectedValue, dates);
                                                }}
                                            />
                                            <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                                        </div>
                                    </div>

                                </Row>
                            </form>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                {PermissionHelper.PermissionChecker('view_dashboard_reports.active_operators') && (
                    <Col lg={4} md={6}>
                        <div className="d-flex h-100">
                            <Card className="card-animate flex-grow-1">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Active Operators')}</p>
                                        </div>
                                    </div>
                                    {topOperator.length > 0 ? (
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                {topOperator.map(operator => (
                                                    <p key={operator.id}>{operator.operatorname}</p>
                                                ))}
                                            </div>
                                            <div>
                                                {topOperator.map(operator => (
                                                    <p key={operator.id}>Users : <strong>{operator.user_count}</strong></p>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-center mt-3">{t('No Records Found')}</p>
                                    )}
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                )}
                {PermissionHelper.PermissionChecker('view_dashboard_reports.best_operators') && (
                    <Col lg={4} md={6}>
                        <div className="d-flex h-100">
                            <Card className="card-animate flex-grow-1">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Best Operators')}</p>
                                        </div>
                                    </div>
                                    {bestOperators.length > 0 ? (
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                {bestOperators.map(operator => (
                                                    <p key={operator.operatorname}>{operator.operatorname}</p>
                                                ))}
                                            </div>
                                            <div>
                                                {bestOperators.map(operator => (
                                                    <p key={operator.operatorname}>GGR : <strong>€{operator.ggr_percent}</strong> </p>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-center mt-3">{t('No Records Found')}</p>
                                    )}
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                )}
                {PermissionHelper.PermissionChecker('view_dashboard_reports.losses_in_operators') && (
                    <Col lg={4} md={6}>
                        <div className="d-flex h-100">
                            <Card className="card-animate flex-grow-1">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Losses in Operators')}</p>
                                        </div>
                                    </div>
                                    {topOperatorsLosses.length > 0 ? (
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                {topOperatorsLosses.map(operator => (
                                                    <p key={operator.operatorname}>{operator.operatorname}</p>
                                                ))}
                                            </div>
                                            <div>
                                                {topOperatorsLosses.map(operator => (
                                                    <p key={operator.operatorname}><strong>€{operator.losses} </strong></p>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-center mt-3">{t('No Records Found')}</p>
                                    )}
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                )}
            </Row>




            <Row >
                {(
                    PermissionHelper.PermissionChecker('gaming_activity.ggr_report') ||
                    PermissionHelper.PermissionChecker('gaming_activity.total_bet') ||
                    PermissionHelper.PermissionChecker('gaming_activity.total_win') ||
                    PermissionHelper.PermissionChecker('gaming_activity.most_ggr_by_player') ||
                    PermissionHelper.PermissionChecker('gaming_activity.most_games_by_player')
                ) &&
                    <>
                        <h5 className="mb-3">{t('Gaming Activity')}</h5>

                        {PermissionHelper.PermissionChecker('gaming_activity.total_bet') && (
                            <React.Fragment>
                                <Col lg={4} md={6}>
                                    <div className="d-flex h-100">
                                        <Card className="card-animate flex-grow-1">
                                            <CardBody>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1 overflow-hidden">
                                                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Total Bet')}</p>
                                                    </div>
                                                </div>
                                                {topOperatorsBets.length > 0 ? (
                                                    <div className="d-flex align-items-end justify-content-between mt-4">
                                                        <div>
                                                            {topOperatorsBets.map(operator => (
                                                                <p key={operator.operatorname}>{operator.operatorname}</p>
                                                            ))}
                                                        </div>
                                                        <div>
                                                            {topOperatorsBets.map(operator => (
                                                                <p key={operator.operatorname}>Bets : <strong>{operator.total_bets}</strong> </p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <p className="text-center mt-3">{t('No Records Found')}</p>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </div>
                                </Col>
                            </React.Fragment>
                        )}
                        {PermissionHelper.PermissionChecker('gaming_activity.total_win') && (
                            <Col lg={4} md={6}>
                                <div className="d-flex h-100">
                                    <Card className="card-animate flex-grow-1">
                                        <CardBody>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Total Wins Amount')}</p>
                                                </div>
                                            </div>
                                            {topOperatorsWins.length > 0 ? (
                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                    <div>
                                                        {topOperatorsWins.map(operator => (
                                                            <p key={operator.operatorname}>{operator.operatorname}</p>
                                                        ))}
                                                    </div>
                                                    <div>
                                                        {topOperatorsWins.map(operator => (
                                                            <p key={operator.operatorname}>Wins : <strong>€{operator.total_wins}</strong> </p>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (
                                                <p className="text-center mt-3">{t('No Records Found')}</p>
                                            )}
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        )}
                        {PermissionHelper.PermissionChecker('gaming_activity.ggr_report') && (
                            <Col lg={4} md={6}>
                                <div className="d-flex h-100">
                                    <Card className="card-animate flex-grow-1">
                                        <CardBody>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1 overflow-hidden">
                                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('GGR Report')}</p>
                                                </div>
                                            </div>
                                            {totalGGRandBetsWins ? (
                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                    <div>
                                                        <p>{t('Total Bets Amount')}</p>
                                                        <p>{t('Total Wins Amount')}</p>
                                                        <strong>{t('Total Balance Amount')}</strong>
                                                    </div>
                                                    <div>
                                                        <p><strong>€{totalGGRandBetsWins.total_bets}</strong> </p>
                                                        <p><strong>€{totalGGRandBetsWins.total_wins}</strong> </p>
                                                        <strong> €{totalGGRandBetsWins.total_ggr}</strong>
                                                    </div>
                                                </div>
                                            ) : (
                                                <p className="text-center mt-3">{t('No Records Found')}</p>
                                            )}
                                        </CardBody>
                                    </Card>
                                </div>
                            </Col>
                        )}
                    </>

                }
            </Row>
            <Row >
                {PermissionHelper.PermissionChecker('gaming_activity.most_ggr_by_player') && (
                    <Col lg={4} md={6}>
                        <div className="d-flex h-100">
                            <Card className="card-animate flex-grow-1">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Most GGR - By Player')}</p>
                                        </div>
                                    </div>
                                    {ggrOfUsers.length > 0 ? (
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                {ggrOfUsers.map(operator => (
                                                    <p key={operator.username}>{operator.username}</p>
                                                ))}
                                            </div>
                                            <div>
                                                {ggrOfUsers.map(operator => (
                                                    <p key={operator.username}><strong>€{parseFloat(operator.ggr.toFixed(2))} </strong></p>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-center mt-3">{t('No Records Found')}</p>
                                    )}
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                )}

                {PermissionHelper.PermissionChecker('gaming_activity.most_games_by_player') && (
                    <Col lg={4} md={6}>
                        <div className="d-flex h-100">
                            <Card className="card-animate flex-grow-1">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Most Games - By Player')} </p>
                                        </div>
                                    </div>
                                    {mostGamesByPlayer.length > 0 ? (
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                {mostGamesByPlayer.map(operator => (
                                                    <p key={operator.username}>{operator.username}</p>
                                                ))}
                                            </div>
                                            <div>
                                                {mostGamesByPlayer.map(operator => (
                                                    <p key={operator.username}>No of Games: <strong>{operator.games}</strong> </p>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p>{t('No Records Found')}</p>
                                    )}
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                )}
                {PermissionHelper.PermissionChecker('gaming_activity.most_games_by_player') && (
                    <Col lg={4} md={6}>
                        <div className="d-flex h-100">
                            <Card className="card-animate flex-grow-1">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Most Played Brands')}</p>
                                        </div>
                                    </div>
                                    {mostPlayedBrands && mostPlayedBrands.length > 0 ? (
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                {mostPlayedBrands.map(operator => (
                                                    <p key={operator.platform}>{t('Brand Name')}</p>
                                                ))}
                                            </div>
                                            <div>
                                                {mostPlayedBrands.map(operator => (
                                                    <p key={operator.platform}><strong>{operator.platform}</strong> </p>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-center mt-3">{t('No Records Found')}</p>
                                    )}
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                )}
                {PermissionHelper.PermissionChecker('gaming_activity.most_games_by_player') && (
                    <Col lg={4} md={6}>
                        <div className="d-flex h-100">
                            <Card className="card-animate flex-grow-1">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t("Most Profitable Brands")}</p>
                                        </div>
                                    </div>
                                    {mostProfitableBrands.length > 0 ? (
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                {mostProfitableBrands.map(operator => (
                                                    <p key={operator.platform}>{operator.platform}</p>
                                                ))}
                                            </div>
                                            <div>
                                                {mostProfitableBrands.map(operator => (
                                                    <p key={operator.total_profit}><strong>€{operator.total_profit}</strong> </p>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-center mt-3">{t('No Records Found')}</p>
                                    )}
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                )}
                {PermissionHelper.PermissionChecker('gaming_activity.most_games_by_player') && (
                    <Col lg={4} md={6}>
                        <div className="d-flex h-100">
                            <Card className="card-animate flex-grow-1">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Most Played Games')}</p>
                                        </div>
                                    </div>
                                    {mostPlayedGames.length > 0 ? (
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                {mostPlayedGames.map(operator => (
                                                    <p key={operator.game_name}>{t('Game Name')}</p>
                                                ))}
                                            </div>
                                            <div>
                                                {mostPlayedGames.map(operator => (
                                                    <p key={operator.game_name}><strong>{operator.game_name}</strong> </p>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-center mt-3">{t('No Records Found')}</p>
                                    )}
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                )}
                {PermissionHelper.PermissionChecker('gaming_activity.most_games_by_player') && (
                    <Col lg={4} md={6}>
                        <div className="d-flex h-100">
                            <Card className="card-animate flex-grow-1">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Most Profitable Games')}</p>
                                        </div>
                                    </div>
                                    {mostProfitableGames.length > 0 ? (
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                {mostProfitableGames.map(operator => (
                                                    <p key={operator.game_name}>{operator.game_name}</p>
                                                ))}
                                            </div>
                                            <div>
                                                {mostProfitableGames.map(operator => (
                                                    <p key={operator.total_profit}><strong>€{operator.total_profit}</strong> </p>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-center mt-3">{t('No Records Found')}</p>
                                    )}
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                )}

            </Row>


            <Row >
                {(PermissionHelper.PermissionChecker('game_provider_dashboard.most_played_game_provider') ||
                    PermissionHelper.PermissionChecker('game_provider_dashboard.most_profitable_game_provider')) && (
                        <>
                            <h5 className="mb-3">{t('Game Provider')}</h5>
                            {PermissionHelper.PermissionChecker('game_provider_dashboard.most_played_game_provider') && (
                                <React.Fragment>
                                    <Col lg={4} md={6}>
                                        <div className="d-flex h-100">
                                            <Card className="card-animate flex-grow-1">
                                                <CardBody>
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-grow-1 overflow-hidden">
                                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Most Played Game Provider')}</p>
                                                        </div>
                                                    </div>
                                                    {mostPlayedGameProvider.length > 0 ? (
                                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                                            <div>
                                                                {mostPlayedGameProvider.map(operator => (
                                                                    <p key={operator.vendor_name}>{operator.vendor_name}</p>
                                                                ))}
                                                            </div>
                                                            <div>
                                                                {mostPlayedGameProvider.map(operator => (
                                                                    <p key={operator.vendor_name}>No of Games: <strong>{operator.game_providers}</strong> </p>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <p className="text-center mt-3">{t('No Records Found')}</p>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </Col>
                                </React.Fragment>
                            )}
                        </>
                    )}
                {PermissionHelper.PermissionChecker('game_provider_dashboard.most_profitable_game_provider') && (
                    <Col lg={4} md={6}>
                        <div className="d-flex h-100">
                            <Card className="card-animate flex-grow-1">
                                <CardBody>
                                    <div className="d-flex align-items-center">
                                        <div className="flex-grow-1 overflow-hidden">
                                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{t('Most Profitable Game Provider')}</p>
                                        </div>
                                    </div>
                                    {mostProfitableProviders.length > 0 ? (
                                        <div className="d-flex align-items-end justify-content-between mt-4">
                                            <div>
                                                {mostProfitableProviders.map(operator => (
                                                    <p key={operator.vendor_name}>{operator.vendor_name}</p>
                                                ))}
                                            </div>
                                            <div>
                                                {mostProfitableProviders.map(operator => (
                                                    <p key={operator.vendor_name}><strong>€{operator.total_profit}</strong> </p>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <p className="text-center mt-3">{t('No Records Found')}</p>
                                    )}
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                )}


            </Row>

        </React.Fragment >
    );
};

export default Widgets;