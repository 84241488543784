import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isDashboard, setIsDashboard] = useState<boolean>(false);
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [isPages, setIsPages] = useState<boolean>(false);
    const [isReport, setIsReport] = useState<boolean>(false);
    const [isUser, setIsUser] = useState<boolean>(false);
    const [isBaseUi, setIsBaseUi] = useState<boolean>(false);
    const [isAdvanceUi, setIsAdvanceUi] = useState<boolean>(false);
    const [isForms, setIsForms] = useState<boolean>(false);
    const [isTables, setIsTables] = useState<boolean>(false);
    const [isCharts, setIsCharts] = useState<boolean>(false);
    const [isIcons, setIsIcons] = useState<boolean>(false);
    const [isMaps, setIsMaps] = useState<boolean>(false);
    const [isMultiLevel, setIsMultiLevel] = useState<boolean>(false);




    // Authentication

    // Pages
    // const [isProfile, setIsProfile] = useState<boolean>(false);



    // Multi Level
    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e: any) {
        if (e && e.target && e.target.getAttribute("sub-items")) {
            const ul: any = document.getElementById("two-column-menu");
            const iconItems: any = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("sub-items");
                const getID = document.getElementById(id) as HTMLElement
                if (getID)
                    getID.classList.remove("show");
            });
        }
    }
    var menuItem: any[];
    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }

        if (iscurrentState !== 'Auth') {
            setIsAuth(false);
        }
        if (iscurrentState !== 'Pages') {
            setIsPages(false);
        }
        if (iscurrentState !== 'BaseUi') {
            setIsBaseUi(false);
        }
        if (iscurrentState !== 'Reports') {
            setIsReport(false);
        }
        if (iscurrentState !== 'AdvanceUi') {
            setIsAdvanceUi(false);
        }
        if (iscurrentState !== 'Forms') {
            setIsForms(false);
        }
        if (iscurrentState !== 'Tables') {
            setIsTables(false);
        }
        if (iscurrentState !== 'Charts') {
            setIsCharts(false);
        }
        if (iscurrentState !== 'Icons') {
            setIsIcons(false);
        }
        if (iscurrentState !== 'Maps') {
            setIsMaps(false);
        }
        if (iscurrentState !== 'MuliLevel') {
            setIsMultiLevel(false);
        }
        if (iscurrentState === 'Widgets') {
            history("/widgets");
            document.body.classList.add('twocolumn-panel');
        }

    }, [
        history,
        iscurrentState,
        isDashboard,
        isAuth,
        isPages,
        isBaseUi,
        isAdvanceUi,
        isForms,
        isTables,
        isCharts,
        isIcons,
        isMaps,
        isMultiLevel
    ]);
    let Adminrole: string | null = null;
    var permissions_keys: any[] | null = null;
    var permissions_reprts: any[] | null = null;
    if (!Adminrole) {
        const authUserstring = sessionStorage.getItem('authUser');
        if (authUserstring) {
            const authUser = JSON.parse(authUserstring);
            if (authUser && authUser.data.role) {
                Adminrole = authUser.data.role;

                if (authUser.data.permissions) {
                    permissions_keys = Object.keys(authUser.data.permissions);
                    if (Adminrole == 'subadmin') {
                        if (authUser.data.permissions.reports) {

                            permissions_reprts = Object.keys(authUser.data.permissions?.reports);
                        }
                    }
                }
            }
        }
    }

    if (Adminrole === 'superadmin') {
        menuItem = [
            {
                label: "Menu",
                isHeader: true,
            },
            {
                id: "ecommerce",
                label: "Dashboard",
                icon: "ri-dashboard-2-line",
                link: "/#",
                stateVariables: isDashboard,
                click: function (e: any) {
                    e.preventDefault();
                    setIsDashboard(!isDashboard);
                    setIscurrentState('Dashboard');
                    updateIconSidebar(e);
                },

            },


            {
                label: "pages",
                isHeader: true,
            },

            {
                id: "GameProvider",
                label: "Game Providers",
                icon: "ri-gamepad-line",
                link: "/Game-provider",
                click: function (e: any) {
                    e.preventDefault();
                    setIscurrentState('Gameprovider');
                }
            },
            {
                id: "Operatortenants",
                label: "Operator Tenants",
                icon: "ri-map-pin-user-line",
                link: "/Operator-tenant",
                click: function (e: any) {
                    e.preventDefault();
                    setIscurrentState('Operator');
                }
            },
            // {
            //     id: "Subadmin",
            //     label: "Add Sub Admin",
            //     icon: "ri-user-add-fill",
            //     link: "/Add-Sub-Admin",
            //     click: function (e: any) {
            //         e.preventDefault();
            //         setIscurrentState('Operator');
            //     }
            // },
            {
                id: "users",
                label: "Users",
                icon: "ri-user-line",
                link: "/All-users",
                click: function (e: any) {
                    e.preventDefault();
                    setIscurrentState('Users');
                    updateIconSidebar(e);
                },
                // stateVariables: isUser,
                // subItems: [
                //     { id: "allusers", label: "All Users", link: "/All-users", parentId: "users" },

                //     { id: "blocked", label: "Blocked Users", link: "/Blocked-users", parentId: "users" },

                // ],
            },

            {
                id: "reports",
                label: "Reports",
                icon: "ri-bar-chart-2-line",
                link: "/#",
                click: function (e: any) {
                    e.preventDefault();
                    setIsReport(!isReport);
                    setIscurrentState('Reports');
                    updateIconSidebar(e);
                },
                stateVariables: isReport,
                subItems: [
                    { id: "Transaction", label: "Unified Transactions", link: "/Game-transaction-report", parentId: "reports" },

                    { id: "Player", label: "Player Report", link: "/Player-report", parentId: "reports" },

                ],
            },
            // {
            //     id: "Usage Statics",
            //     label: "Usage Statics",
            //     icon: "ri-database-2-line",
            //     link: "/Non-cash-bonus-transaction",
            //     click: function (e: any) {
            //         e.preventDefault();
            //         setIscurrentState('Statics');
            //     }
            // },
            {
                id: "VPN Setup",
                label: "VPN Setup",
                icon: "ri-globe-line",
                link: "/Non-cash-bonus-transaction",
                click: function (e: any) {
                    e.preventDefault();
                    setIscurrentState('VPN');
                }
            },
            {
                id: "IP Blocking",
                label: "IP Blocking",
                icon: "ri-lock-2-line",
                link: "/ip-blocked-list",
                click: function (e: any) {
                    e.preventDefault();
                    setIscurrentState('IP');
                }
            },
            {
                id: "currencies",
                label: "Manage Currencies",
                icon: "ri-exchange-dollar-line",
                link: "/currencies",
                click: function (e: any) {
                    e.preventDefault();
                    setIscurrentState('currencies');
                }
            },
            {
                id: "invoices",
                label: "Invoices",
                icon: "ri-file-list-3-line",
                link: "/invoices",
                click: function (e: any) {
                    e.preventDefault();
                    setIscurrentState('invoices');
                }
            },

        ];
    } else {
        if (Adminrole === 'subadmin') {
            const all_menu_items = [
                {
                    label: "Menu",
                    isHeader: true,
                },
                {
                    id: "ecommerce",
                    label: "Dashboard",
                    icon: "ri-dashboard-2-line",
                    link: "/#",
                    stateVariables: isDashboard,
                    click: function (e: any) {
                        e.preventDefault();
                        setIsDashboard(!isDashboard);
                        setIscurrentState('Dashboard');
                        updateIconSidebar(e);
                    },
                },


                {
                    label: "pages",
                    isHeader: true,
                },

                {
                    id: "GameProvider",
                    label: "Game Providers",
                    icon: "ri-gamepad-line",
                    link: "/Game-provider",
                    click: function (e: any) {
                        e.preventDefault();
                        setIscurrentState('Gameprovider');
                    }
                },
                {
                    id: "Operatortenants",
                    label: "Operator Tenants",
                    icon: "ri-map-pin-user-line",
                    link: "/Operator-tenant",
                    click: function (e: any) {
                        e.preventDefault();
                        setIscurrentState('Operator');
                    }
                },

                { id: "Transaction", label: "Unified Transactions", link: "/Game-transaction-report" },

                { id: "Player", label: "Player Report", link: "/Player-report" },
                // {
                //     id: "Usage Statics",
                //     label: "Usage Statics",
                //     icon: "ri-database-2-line",
                //     link: "/Non-cash-bonus-transaction",
                //     click: function (e: any) {
                //         e.preventDefault();
                //         setIscurrentState('Statics');
                //     }
                // },
                {
                    id: "VPN Setup",
                    label: "VPN Setup",
                    icon: "ri-globe-line",
                    link: "/Non-cash-bonus-transaction",
                    click: function (e: any) {
                        e.preventDefault();
                        setIscurrentState('VPN');
                    }
                },
                {
                    id: "IP Blocking",
                    label: "IP Blocking",
                    icon: "ri-lock-2-line",
                    link: "/ip-blocking",
                    click: function (e: any) {
                        e.preventDefault();
                        setIscurrentState('IP');
                    }
                },
                {
                    id: "currencies",
                    label: "Manage Currencies",
                    icon: "ri-exchange-dollar-line",
                    link: "/currencies",
                    click: function (e: any) {
                        e.preventDefault();
                        setIscurrentState('currencies');
                    }
                },
                {
                    id: "invoices",
                    label: "Invoices",
                    icon: "ri-file-list-3-line",
                    link: "/invoices",
                    click: function (e: any) {
                        e.preventDefault();
                        setIscurrentState('invoices');
                    }
                },


            ];
            //console.log("all_menu_items",all_menu_items);
            menuItem = [
                {
                    label: "pages",
                    isHeader: true,
                },
                {
                    id: "ecommerce",
                    label: "Dashboard",
                    icon: "ri-dashboard-2-line",
                    link: "/#",
                    stateVariables: isDashboard,
                    click: function (e: any) {
                        e.preventDefault();
                        setIsDashboard(!isDashboard);
                        setIscurrentState('Dashboard');
                        updateIconSidebar(e);
                    },
                },



                {
                    label: "Menu",
                    isHeader: true,
                },

            ];
            console.log("permissions_keys", permissions_keys);
            permissions_keys?.forEach((key, index) => {



                if (key == 'operator_tenants') {
                    menuItem.unshift(

                        ...all_menu_items.filter((item) => item.id === 'Operatortenants')
                    );
                }

                if (key == 'game_provider') {
                    menuItem.unshift(
                        ...all_menu_items.filter((item) => item.id === 'GameProvider')
                    );

                }
                if (key == 'reports') {
                    menuItem.unshift(
                        ...all_menu_items.filter((item) => item.id === 'reports')
                    );
                }



            });
            permissions_reprts?.forEach((key, index) => {



                if (key == 'view_game_transaction_report') {
                    menuItem.unshift(

                        ...all_menu_items.filter((item) => item.id === 'Transaction')
                    );
                }

                if (key == 'player_report') {
                    menuItem.unshift(
                        ...all_menu_items.filter((item) => item.id === 'Player')
                    );

                }
                if (key == 'player_financial_report') {
                    menuItem.unshift(
                        ...all_menu_items.filter((item) => item.id === 'Financial')
                    );
                }

                if (key == 'non_cash_bonus_transaction') {
                    menuItem.unshift(
                        ...all_menu_items.filter((item) => item.id === 'NonCash')
                    );
                }

            });




            menuItem.reverse();






        }
        else {
            menuItem = [
                {
                    label: "Menu",
                    isHeader: true,
                },
                {
                    id: "ecommerce",
                    label: "Dashboard",
                    icon: "ri-dashboard-2-line",
                    link: "/#",
                    stateVariables: isDashboard,
                    click: function (e: any) {
                        e.preventDefault();
                        setIsDashboard(!isDashboard);
                        setIscurrentState('Dashboard');
                        updateIconSidebar(e);
                    },

                },
                {
                    label: "pages",
                    isHeader: true,
                },

                {
                    id: "Viewdocumention",
                    label: "View Documention",
                    icon: "ri-gamepad-line",
                    link: "/View-documentation",
                    click: function (e: any) {
                        e.preventDefault();
                        setIscurrentState('Document');
                    }
                },
                {
                    id: "ViewGeneratedKeys",
                    label: "View Generated Keys (Hub)",
                    icon: "ri-map-pin-user-line",
                    link: "/Generated-keys",
                    click: function (e: any) {
                        e.preventDefault();
                        setIscurrentState('Operator');
                    }
                },
                {
                    id: "yourkeys",
                    label: "Your API Keys",
                    icon: "ri-map-pin-user-line",
                    link: "/your-keys",
                    click: function (e: any) {
                        e.preventDefault();
                        setIscurrentState('yourkeys');
                    }
                },
                {
                    id: "reports",
                    label: "Reports",
                    icon: "ri-bar-chart-2-line",
                    link: "/#",
                    click: function (e: any) {
                        e.preventDefault();
                        setIsReport(!isReport);
                        setIscurrentState('Reports');
                        updateIconSidebar(e);
                    },
                    stateVariables: isReport,
                    subItems: [],
                },


            ];
        }
    }
    if (Adminrole === 'superadmin') {
        menuItem.splice(5, 0, {
            id: "Subadmin",
            label: "Add Sub Admin",
            icon: "ri-user-add-line",
            link: "/Add-Sub-Admin",
            click: function (e: any) {
                e.preventDefault();
                setIscurrentState('Operator');

            }
        });
    }
    if (Adminrole == 'subadmin') {
        if (Adminrole == 'subadmin') {
            // ...existing code...

            menuItem.push({
                id: "Viewdocumention",
                label: "View Documention",
                icon: "ri-gamepad-line",
                link: "/View-documentation",
                click: function (e: any) {
                    e.preventDefault();
                    setIscurrentState('Document');
                }
            });

            // ...existing code...
        }
        menuItem?.forEach((value) => {
            if (value.id == 'reports') {

            }
        });

    }
    const menuItems: any = menuItem;

    //console.log(menuItems);
    return <React.Fragment>{menuItems}</React.Fragment>;

};
export default Navdata;