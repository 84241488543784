
import axios, { AxiosRequestConfig } from 'axios';

export interface OperatorSelected {
  value: string;
  label: string;
}

export interface VendorSelected {
  value: string;
  label: string;
}
export interface DashboardReportsPermissions {
  active_operators: number;
  best_operators: number;
  losses_in_operators: number;
}
export interface FinancialActivityPermissions {
  total_deposits: number;
  total_withdrawals: number;
  balance_report: number;
}
export interface GamingActivityPermissions {
  total_bet: number;
  total_win: number;
  ggr_report: number;
  most_ggr_by_player: number;
  most_games_by_player: number;
}
export interface GameProviderPermissions {
  most_played_game_provider: number;
  most_profitable_game_provider: number;
}
export interface OperatorTenantsPermissions {
  view: number;
  edit: number;
  add: number;
  status_change: number;
  assign_provider: number;
  manage_brand: number;
  manage_ggr: number;
  token_expiry: number;
  add_new_tenant_admin: number;
  view_tenant_admin: number;
  edit_tenant_admin: number;
  manage_games: number;
  advanceConfig:number;
}
export interface ReportsPermissions {
  view_game_transaction_report: number;
  player_report: number;
}
export interface GameProvider {
  view: number;
  edit: number;
  status_change: number;
}
export type AdminData = {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  status_id: number;
};

export type Permissions = {
  view_dashboard_reports: DashboardReportsPermissions;
  financial_activity: FinancialActivityPermissions;
  gaming_activity: GamingActivityPermissions;
  game_provider_dashboard: GameProviderPermissions;
  operator_tenants: OperatorTenantsPermissions;
  reports: ReportsPermissions;
  game_provider: GameProvider;
};

export interface Provider {
  created_at: number;
  id: number;
  vendor_id: number;
  vendor_name: string;
  vendor_status: number;
  number_of_operators: number;
  ggr: number;
  ggr_amount: any;
  rounds: number;
  roundid_count: any
}
export interface HistoryRecord {
  id: number;
  brand_id: number;
  brand_name: string | null;
  game_provider_id: number | null;
  operator_id: number;
  month_year: string;
  ggr_percent: string;
  ngr_percent: string;
  created_at: string;
  updated_at: string;
}
export type BlockedIp = {
  id: number;
  ip: string;
  created_at: string;
  updated_at: string;
};
export interface vendorsList {
  id: number;
  vendor_name: string;
}
export interface Currency {
  id: number;
  name: string;
  code: string;
  exchange_rate: number
  value: string
}
export interface List {
  created_at: number;
  id: number;
  vendor_id: number;
  vendor_name: string;
  vendor_status: number;
  number_of_operators: number;
  ggr: number;
  rounds: number;
}
export interface Tenant {
  id: number;
  operatorname: string;
  registeredOn: number;
  playerCount: number;
  created_at: number;
  ggr_percent: any;
  ngr_percent: any
  NGR: number;
  total_balance: number;
  status: number;
  player_count: any;
  vendor_count: any;
  tenants: any[];
}

export interface Playerreport {
  player_id: number;
  operatorname: string;
  username: string;
  total_balance: number;
  currency: string;
  total_bets: number;
  ggr_percent: number;
  total_bet_amount: number;
  bet_created_at: any;
  user_created_at: any;
  user_ggr: any;
}
export interface FormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  password: string;
  makeOwnerActive: boolean;
  kycVerified: boolean;
}
export interface TenantAdmin {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  role: string;
  status: number;
  kyc_regulated: number;
  status_id: string;

}
export interface Allusers {
  id: number;
  email: string;
  username: string;
  status_id: number;
  operator: string;


}
export interface Operator {
  id: number;
  operatorname: string;
}

export interface User {
  guid: string;
  id: number;
  username: string;
  email: string;
  operator_id: number;
  status_id: number;
  operator: Operator;
}

interface VendorPivot {
  is_vpn_enabled: number;
};

export interface operatorsListReport {
  id: number;
  operatorname: string;
}

export interface Vendor {
  id: number;
  vendor_id: number;
  vendor_status: number;
  vendor_name: string;
  is_vpn_enabled: number;
  pivot: VendorPivot;
}

export interface Game {
  id: number;
  game_id: string;
  name: string;
  games_brands: string
  brand: string
  block_all_operators: any
  checked: any
  show_to_operator: any
  vendorID: any
  block_all_subadmin: any
}

export interface fetchDashboardReportInterface {
  id: number;
  operatorname: string;
  user_count: number;

}

// export const BASE_URL = "http://127.0.0.1:8000/api";      //local

export const BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000';    //server


export const getBearerToken = (): string | null => {
  let bearerToken: string | null = null;
  if (!bearerToken) {
    const authUserstring = sessionStorage.getItem('authUser');
    if (authUserstring) {
      const authUser = JSON.parse(authUserstring);
      if (authUser && authUser.data.token) {
        bearerToken = authUser.data.token;
      }
    }
  }
  return bearerToken;
};

const getHeaders = (): AxiosRequestConfig['headers'] => {
  const token = getBearerToken();
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token || ''}`,
  };
};

export const fetchOperatorTenants = async () => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }

    const apiurl = `${BASE_URL}/tenant-operator/list`;
    const response = await axios.get(apiurl, { headers });
    return response.data;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const fetchOperatorTenantswithpagination = async (page: number) => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }

    const apiurl = `${BASE_URL}/tenant-operator/list-page`;
    const response = await axios.get(apiurl, { params: { 'page': String(page), }, headers });
    return response.data;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const operatorsForReport = async () => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }
    const apiurl = `${BASE_URL}/operators-for-report`;
    const response = await axios.get(apiurl, { headers });
    return response.data;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const fetchGameTransactionrReport = async () => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }
    const apiurl = `${BASE_URL}/gameTransactionReport`;
    const response = await axios.get(apiurl, { headers });
    return response.data;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const AllUsersData = async () => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }
    const apiurl = `${BASE_URL}/users`;
    const response = await axios.get(apiurl, { headers });
    return response.data;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const fetchDashboardReport = async () => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }
    const apiurl = `${BASE_URL}/dashboardReports`;
    const response: any = await axios.get(apiurl, { headers });

    return response.data;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const fetchCurrencylistwithpagination = async (page: number) => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }
    const apiurl = `${BASE_URL}/getCurrenciesWithPagination`;
    const response: any = await axios.get(apiurl, { params: { 'page': String(page), }, headers });

    return response;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const fetchCurrencylist = async () => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }
    const apiurl = `${BASE_URL}/getCurrencies`;
    const response: any = await axios.get(apiurl, { headers });

    return response;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const getCurrencyMargin = async () => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }
    const apiurl = `${BASE_URL}/getCurrencyMargin`;
    const response: any = await axios.get(apiurl, { headers });

    return response.data;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const fetchGameTransactionrReportFilter = async (operator_id: string, vendor_id: string, selectedDate: any, username: string, roundId: string, transactionId: string, internalId: string) => {
  try {

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`,
    };

    const response = await axios.get(`${BASE_URL}/gameTransactionReportFilter`, {
      params: {
        operator_id: operator_id,
        vendor_id: vendor_id,
        selectedDate: selectedDate,
        username: username,
        round_id: roundId,
        platform_txn_id: transactionId,
        internal_id: internalId
      },
      headers: headers,
    });
    return response.data;
  } catch (error: any) {
    throw new Error('Failed to add/update admin: ' + error);
  }
}

export const DashboardReportFilter = async (operator_id: string, selectedDate: any) => {
  try {

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`,
    };

    const response = await axios.get(`${BASE_URL}/dashboardReportsWithFilters`, {
      params: {
        operator_id: operator_id,
        selectedDate: selectedDate,
      },
      headers: headers,
    });

    return response.data;

  } catch (error: any) {
    throw new Error('Failed to add/update admin: ' + error);
  }
}

export const fetchPlayerReport = async () => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }
    const apiurl = `${BASE_URL}/player-report`;
    const response = await axios.get(apiurl, { headers });
    return response.data;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const fetchGameProvider = async () => {
  try {
    const apiurl = `${BASE_URL}/game-providers/list`;
    const response = await axios.get(apiurl, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchAdvConfigData = async (operatorId:number) => {
  try {
    const apiurl = `${BASE_URL}/getVendorsWithConfig`;
    const response = await axios.get(apiurl, { params: { operator_id: operatorId }, headers: getHeaders() });

    //const response = await axios.get(apiurl, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getVendorsWithConfig = async (operatorId:number) => {
  try {
    const apiurl = `${BASE_URL}/getVendorsWithConfig`;
    const response = await axios.get(apiurl, { params: { operator_id: operatorId }, headers: getHeaders() });

    //const response = await axios.get(apiurl, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getVendorsWithConfigSingle = async (vendor_id:number,operatorId:number) => {
  try {
    const apiurl = `${BASE_URL}/getVendorsWithConfigSingle`;
    const response = await axios.get(apiurl, { params: { vendor_id:vendor_id, operator_id: operatorId }, headers: getHeaders() });

    //const response = await axios.get(apiurl, { headers: getHeaders() });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};





 
export const IpBlockLists = async () => {
  try {
    const apiurl = `${BASE_URL}/ipblocks`;
    
    const response = await axios.get(apiurl, { headers: getHeaders() });
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const GGRandNGRHistory = async (brandid: number, operatorId: number) => {
  try {
    const apiurl = `${BASE_URL}/getGGRHistory`;
    const response = await axios.get(apiurl, { params: { brand_id: brandid, operator_id: operatorId }, headers: getHeaders() });
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const GGRandNGRHistorySubAdmin = async (brandid: number, operatorId: number) => {
  try {
    const apiurl = `${BASE_URL}/getGGRHistorySubadmin`;
    const response = await axios.get(apiurl, { params: { brand_id: brandid, subadmin_id: operatorId }, headers: getHeaders() });
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchAdminDetails = async (operator_id: any) => {
  try {
    const apiurl = `${BASE_URL}/getOperatorsCredentials`;
    const response = await axios.get(apiurl, {
      params: { operator_id: operator_id },
      headers: getHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const addOperator = async (operatorName: string, operatorDomain: string,callbackUrl : string, operatorId: any, ggr: any, betLimit: any, selectedCurrency: any, ) => {
  try {
    const response = await fetch(`${BASE_URL}/tenant-operator/add-update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },
      body: JSON.stringify({
        operatorname: operatorName,
        domain: operatorDomain,
        ggr: 0,
        id: operatorId,
        bet_limit: betLimit,
        currencies: selectedCurrency,
        callbackUrl : callbackUrl
      })
    });

    if (!response.ok) {
      // throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('There was a problem with the fetch operation: ' + error);
  }
};

export const addCustomCurrency = async (name: string, code: string, exchange_rate: any) => {
  try {
    const response = await fetch(`${BASE_URL}/addCustomCurrencies`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },
      body: JSON.stringify({
        name: name,
        code: code,
        exchange_rate: exchange_rate
      })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('There was a problem with the fetch operation: ' + error);
  }
};

export const addCurrencyMargin = async (margin: number) => {
  try {
    const response = await fetch(`${BASE_URL}/addCurrencyMargin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },
      body: JSON.stringify({

        margin: margin
      })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('There was a problem with the fetch operation: ' + error);
  }
};

export const addCredit = async (operatorId: any, credit: any, username: any,) => {
  try {

    const response = await fetch(`${BASE_URL}/addCredits`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },

      body: JSON.stringify({
        operator_id: operatorId,
        credit_limit: credit,
        amount: credit,
        given_by_username: username,
        given_by_id: 1
      })

    });

    if (!response.ok) {
      // throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    // throw new Error('There was a problem with the fetch operation: ' + error);
  }
};

export const removeCredit = async (operatorId: any, credit: any, username: any,) => {
  try {

    const response = await fetch(`${BASE_URL}/removeCredits`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },

      body: JSON.stringify({
        operator_id: operatorId,
        credit_limit: credit,
        amount: credit,
        given_by_username: username,
        given_by_id: 1
      })

    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }


    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('There was a problem with the fetch operation: ' + error);
  }
};

export const addOperatorSubscription = async (requestData: { operator_id: number; vendor_ids: number[]; vpn_config: { game_provider_id: number; vpn_enable: number }[]; }) => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

    const addSubscriptionApiUrl = `${BASE_URL}/add-operator-subscription`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };

    const response: any = await axios.post<any>(addSubscriptionApiUrl, requestData, { headers });

    if (response.status !== 'success') {
      if (response.status == 'error') {

        //throw new Error(response.errors);
      }
      //throw new Error('Failed to add providers');
    }

    return response;
  } catch (error) {
    throw new Error('Error adding operator subscription: ' + error);
  }
};

export const addSubadminSubscription = async (requestData: { subadmin_id: any; vendor_ids: number[]; vpn_config: { game_provider_id: number; vpn_enable: number }[]; }) => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

    const addSubscriptionApiUrl = `${BASE_URL}/add-subadmin-subscription`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };

    const response: any = await axios.post<any>(addSubscriptionApiUrl, requestData, { headers });

    if (response.status !== 'success') {
      if (response.status == 'error') {

        //throw new Error(response.errors);
      }
      //throw new Error('Failed to add providers');
    }

    return response;
  } catch (error) {
    throw new Error('Error adding operator subscription: ' + error);
  }
};

export const addBrands = async (requestData: { operator_id: number; brands_ids: number[]; vpn_config_brands: any }) => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

    const addSubscriptionApiUrl = `${BASE_URL}/add-operator-brands-vpn`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };

    const response: any = await axios.post<any>(addSubscriptionApiUrl, requestData, { headers });

    if (response.status !== 'success') {
      throw new Error('Failed to add Brands');
    }

    return response;
  } catch (error) {
    throw new Error('Error adding Brands: ' + error);
  }
};

export const addBrandsSubadmin = async (requestData: { subadmin_id: number; brands_ids: number[]; vpn_config_brands: any }) => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

    const addSubscriptionApiUrl = `${BASE_URL}/add-subadmin-brands-vpn`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };

    const response: any = await axios.post<any>(addSubscriptionApiUrl, requestData, { headers });

    if (response.status !== 'success') {
      throw new Error('Failed to add Brands');
    }

    return response;
  } catch (error) {
    throw new Error('Error adding Brands: ' + error);
  }
};

export const filteredBrands = async (gameProviderId: string) => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

    const addSubscriptionApiUrl = `${BASE_URL}/getBransByProviderId`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };

    const response: any = await axios.get<any>(addSubscriptionApiUrl, {
      params: { game_provider_id: gameProviderId },
      headers: headers
    });

    if (response.status !== 'success') {
      throw new Error('Failed to add Brands');
    }

    return response;
  } catch (error) {
    throw new Error('Error adding Brands: ' + error);
  }
};

export const filteredBrandsProvider = async (brands_ids: string) => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

    const addSubscriptionApiUrl = `${BASE_URL}/getGamesByBrandsFilter`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };

    const response: any = await axios.get<any>(addSubscriptionApiUrl, {
      params: { brands_ids: brands_ids },
      headers: headers
    });

    if (response.status !== 'success') {
      throw new Error('Failed to add Brands');
    }

    return response;
  } catch (error) {
    throw new Error('Error adding Brands: ' + error);
  }
};

export const GpOperators = async (gameProviderId: string) => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

    const addSubscriptionApiUrl = `${BASE_URL}/getOperatorsByVendor`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };

    const response: any = await axios.get<any>(addSubscriptionApiUrl, {
      params: { game_provider_id: gameProviderId }, // Pass as an object
      headers: headers
    });

    if (response.status !== 'success') {
      throw new Error('Failed to add Brands');
    }

    return response;
  } catch (error) {
    throw new Error('Error adding Brands: ' + error);
  }
};

export const addGgr = async (requestData: { operator_id: number; ggr_brands: { brand_id: number; ggr_percent: string; ngr_percent: string; }[] }) => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

    const addSubscriptionApiUrl = `${BASE_URL}/insertGgrBrands`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };

    const response: any = await axios.post<any>(addSubscriptionApiUrl, requestData, { headers });

    if (response.status !== 'success') {
      throw new Error('Failed to add GGR');
    }
    return response;
  } catch (error) {
    throw new Error('Error adding GGR: ' + error);
  }
};

export const UserBlockbyGp = async (payload: { id: number; game_provider_ids: string }) => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }
    const addSubscriptionApiUrl = `${BASE_URL}/userBlockByGp`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };

    const response: any = await axios.put<any>(addSubscriptionApiUrl, payload, { headers });

    if (response.status !== 'success') {
      throw new Error('Failed to add GGR');
    }
    return response;
  } catch (error) {
    throw new Error('Error adding GGR: ' + error);
  }
};

export const editGgrandNgr = async (payload: { operator_id: any; brand_id: any, date: string, ggr_percent: any, ngr_percent: any }) => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

    const addSubscriptionApiUrl = `${BASE_URL}/editGGRHistory`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };

    const response: any = await axios.put<any>(addSubscriptionApiUrl, payload, { headers });

    if (response.status !== 'success') {
      throw new Error('Failed to add GGR');
    }

    return response;
  } catch (error) {
    throw new Error('Error adding GGR: ' + error);
  }
};

export const editGgrandNgrSubadmin = async (payload: { subadmin_id: any; brand_id: any, date: string, ggr_percent: any, ngr_percent: any }) => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

    const addSubscriptionApiUrl = `${BASE_URL}/editGGRHistorySubadmin`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };

    const response: any = await axios.put<any>(addSubscriptionApiUrl, payload, { headers });

    if (response.status !== 'success') {
      throw new Error('Failed to add GGR');
    }

    return response;
  } catch (error) {
    throw new Error('Error adding GGR: ' + error);
  }
};

export const addGgrsubadmin = async (requestData: { subadmin_id: number; ggr_brands: { brand_id: number; ggr_percent: string; ngr_percent: string; }[] }) => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }
    const addSubscriptionApiUrl = `${BASE_URL}/insertGgrBrandsSubadmin`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };
    const response: any = await axios.post<any>(addSubscriptionApiUrl, requestData, { headers });

    if (response.status !== 'success') {
      throw new Error('Failed to add GGR');
    }
    return response;
  } catch (error) {
    throw new Error('Error adding GGR: ' + error);
  }
};

export const blockIP = async (ip: string) => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }
    const addSubscriptionApiUrl = `${BASE_URL}/ipblocks`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };
    const payload = {
      ip: ip
    };
    const response: any = await axios.post<any>(addSubscriptionApiUrl, payload, { headers });
    return response;
  } catch (error) {
    throw new Error('Error in blocking IP: ' + error);
  }
};

export const addGames = async (requestData: { operator_id: number; games_ids: number[]; games_block_arr: any }) => {
  try {
    const bearerToken = getBearerToken();
    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }
    const addSubscriptionApiUrl = `${BASE_URL}/save-games-of-operator`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };
    const response: any = await axios.post<any>(addSubscriptionApiUrl, requestData, { headers });

    if (response.status !== 'success') {
      throw new Error('Failed to add games');
    }
    return response;
  } catch (error) {
    throw new Error('Error adding games: ' + error);
  }
};



export const resetAdvConfig = async (vendor_id: any,operator_id:any) => {
  try {
    const bearerToken = getBearerToken();
    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }
    const  requestData = {
      operator_id : operator_id,
      vendor_id : vendor_id,
    };
    const saveConfigUrl = `${BASE_URL}/reset-advance-config`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };
    const response: any = await axios.post<any>(saveConfigUrl, requestData, { headers });

    // if (response.status !== 'success') {
    //   toast.success(response.message || 'Operator Added Successfully!', {
    //     autoClose: 1000,
    //   });
    // }
    return response;
  } catch (error) {
    throw new Error('Error save config: ' + error);
  }
};
export const saveAdvanceConfig = async (operator_id: any, vendor_id: any,api_key:any,secret_key:any) => {
  try {
    const bearerToken = getBearerToken();
    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }
    const  requestData = {
      operator_id : operator_id,
      vendor_id : vendor_id,
      api_key:api_key,
      secret_key:secret_key
    };
    const saveConfigUrl = `${BASE_URL}/save-advance-config`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };
    const response: any = await axios.post<any>(saveConfigUrl, requestData, { headers });

    // if (response.status !== 'success') {
    //   toast.success(response.message || 'Operator Added Successfully!', {
    //     autoClose: 1000,
    //   });
    // }
    return response;
  } catch (error) {
    throw new Error('Error save config: ' + error);
  }
};

export const addSubAdminGames = async (requestData: { subadmin_id: number; games_ids: number[]; games_block_arr: any }) => {
  try {
    const bearerToken = getBearerToken();
    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }
    const addSubscriptionApiUrl = `${BASE_URL}/save-games-of-subadmin`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };
    const response: any = await axios.post<any>(addSubscriptionApiUrl, requestData, { headers });

    if (response.status !== 'success') {
      throw new Error('Failed to add games');
    }

    return response;
  } catch (error) {
    throw new Error('Error adding games: ' + error);
  }
};

export const providerBlockGames = async (requestData: { provider_ids: number; games_ids: number[]; games_block_arr: any, brands_ids: any }) => {
  try {
    const bearerToken = getBearerToken();
    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }
    const addSubscriptionApiUrl = `${BASE_URL}/block-all-operators-games`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };

    const response: any = await axios.post<any>(addSubscriptionApiUrl, requestData, { headers });

    if (response.status !== 'success') {
      throw new Error('Failed to add games');
    }

    return response;
  } catch (error) {
    throw new Error('Error adding games: ' + error);
  }
};

export const providerBlockBrands = async (requestData: { brand_id: any, vendor_id: string }) => {
  try {
    const bearerToken = getBearerToken();
    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

    const addSubscriptionApiUrl = `${BASE_URL}/blockBrandsFromVendors`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`
    };

    const response: any = await axios.post<any>(addSubscriptionApiUrl, requestData, { headers });

    if (response.status !== 'success') {
      throw new Error('Failed to add games');
    }

    return response;
  } catch (error) {
    throw new Error('Error adding games: ' + error);
  }
};

export const updateGameProviderStatus = async (id: number, updatedStatus: number) => {
  try {
    const response = await fetch(`${BASE_URL}/GameVendorStatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },
      body: JSON.stringify({
        vendor_status: updatedStatus,
        id: id
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update status');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating status:', error);
    throw new Error('Failed to update status');
  }
};

export const updateTenantStatus = async (id: number, status: number) => {
  try {
    const response = await fetch(`${BASE_URL}/tenant-operator/change-status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },
      body: JSON.stringify({
        status,
        id
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update tenant status');
    }

    return await response.json();
  } catch (error) {
    throw new Error('Error updating tenant status: ' + error);
  }
};

export const updateUserStatus = async (id: number, status: any) => {
  try {
    const response = await axios.put(`${BASE_URL}/updateUser`,
      {
        status: status,
        id: id
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getBearerToken()}`
        },
      }
    );

    if (!response) {
      throw new Error('Failed to update tenant status');
    }

    return await response;
  } catch (error) {
    throw new Error('Error updating tenant status: ' + error);
  }
};

export const updateTenantAdminStatus = async (id: number, status_id: number) => {
  try {
    const response = await fetch(`${BASE_URL}/tenant-admin/change-status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },
      body: JSON.stringify({
        status_id,
        id
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update tenant status');
    }

    return await response.json();
  } catch (error) {
    throw new Error('Error updating tenant status: ' + error);
  }
};

export const IpWhitelist = async (requestData: { operator_id: number, ip: any }): Promise<any> => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }
    const { operator_id, ip } = requestData;
    const response = await fetch(`${BASE_URL}/add-operator-ip`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },
      body: JSON.stringify({
        operator_id,
        ip
      })
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error('There was a problem with the fetch operation: ' + error);
  }
};

export const BrandsGameList = async (requestData: { vendor_id: any }): Promise<any> => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }
    const { vendor_id } = requestData;
    const queryParams = new URLSearchParams({ vendor_id }).toString();
    const url = `${BASE_URL}/brands-by-providers?${queryParams}`;

    const response: any = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },
    });

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const GameList = async (requestData: { provider_ids: any, operator_id: any, brands_ids: any }): Promise<any> => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }
    const { provider_ids, operator_id, brands_ids } = requestData;
    const queryParams = new URLSearchParams({ provider_ids, brands_ids, operator_id }).toString();
    const url = `${BASE_URL}/games-by-provider-brands?${queryParams}`;

    const response: any = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },
    });

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const subAdminGameList = async (requestData: { provider_ids: any, subadmin_id: any, brands_ids: any }): Promise<any> => {
  try {
    const bearerToken = getBearerToken();
    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }
    const { provider_ids, subadmin_id, brands_ids } = requestData;
    const queryParams = new URLSearchParams({ provider_ids, brands_ids, subadmin_id }).toString();
    const url = `${BASE_URL}/games-by-provider-brands-subadmin?${queryParams}`;

    const response: any = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },
    });

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


export const fetchAdvanceConfigForAdmin = async (operator_id:any) => {
  try {
    const bearerToken = getBearerToken();
    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

   
    const url = `${BASE_URL}/fetchAdvanceConfigForAdmin?operator_id=`+operator_id;

    const response: any = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },
    });

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
export const providerGameList = async (requestData: { provider_ids: any, brands_ids: any }): Promise<any> => {
  try {
    const bearerToken = getBearerToken();
    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

    const { provider_ids, brands_ids } = requestData;
    const queryParams = new URLSearchParams({ provider_ids, brands_ids }).toString();
    const url = `${BASE_URL}/getGamesByBrands?${queryParams}`;

    const response: any = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getBearerToken()}`
      },
    });

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const Iplist = async (operator_id: number, ip: any): Promise<any> => {
  try {
    const headers = getHeaders();
    const apiUrl = `${BASE_URL}/list-operator-ip`;

    const params = {
      operator_id: operator_id,
      ip: ip
    };

    const response: any = await axios.get(apiUrl, { headers: headers, params: params });

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const creditlist = async (operator_id: number): Promise<any> => {
  try {
    const headers = getHeaders();
    const apiUrl = `${BASE_URL}/operatorCreditRecords`;

    const params = {
      id: operator_id,
    };
    const response: any = await axios.get(apiUrl, { headers: headers, params: params });

    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const CompensateInvoice = async (subadmin_id: number, month: any, year: any): Promise<any> => {
  try {
    const headers = getHeaders();
    const apiUrl = `${BASE_URL}/invoiceCompensate`;

    const params = {
      id: subadmin_id,
      month: month,
      year: year
    };

    const response: any = await axios.get(apiUrl, { headers: headers, params: params });

    return response;
  } catch (error) {
 
  }
};

export const NotCompensateInvoice = async (subadmin_id: number, month: any, year: any): Promise<any> => {
  try {
    const headers = getHeaders();
    const apiUrl = `${BASE_URL}/invoiceNotCompensate`;
    const params = {
      id: subadmin_id,
      month: month,
      year: year

    };
    const response: any = await axios.get(apiUrl, { headers: headers, params: params });
    return response;
  } catch (error) {
  
  }
};

export const SingleOperatorInvoice = async (operator_id: number, month: any, year: any): Promise<any> => {
  try {
    const headers = getHeaders();
    const apiUrl = `${BASE_URL}/singleOperatorInvoice`;
    const params = {
      id: operator_id,
      month: month,
      year: year


    };

    const response: any = await axios.get(apiUrl, { headers: headers, params: params });
    return response;
  } catch (error) {
   
  }
};


export const getListTenantAdmin = async (operator_id: number,): Promise<any> => {
  try {
    const bearerToken = getBearerToken();

    if (!bearerToken) {
      throw new Error('Bearer token not found');
    }

    const apiUrl = `${BASE_URL}/tenant-admin/list`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`,
    };

    const params = {
      id: operator_id,
      page: 1,
      page_size: 10,
      sort_by: "id",
      sort_order: "desc"
    };

    const response: any = await axios.get(apiUrl, {
      headers,
      params
    });

    if (response.success !== true) {
      throw new Error('Failed to fetch Tenant admin list');
    }

    return response;
  } catch (error: any) {
    console.error('Error fetching Tenant admin list:', error.message);
    throw error;
  }
};

export const playerReportFilter = async (operator_id: string, vendor_id: string, selectedDate: any | null) => {
  try {

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`,
    };

    const response = await axios.get(`${BASE_URL}/playerReportFilter`, {
      params: {
        operator_id: operator_id,
        vendor_id: vendor_id,
        selectedDate: selectedDate,
      },
      headers: headers,
    });

    return response.data;

  } catch (error: any) {
    throw new Error('Failed to add/update admin: ' + error);
  }
};

export const addOrUpdateAdmin = async (operator_id: number, requestBody: any, bearerToken: string | null) => {
  try {

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`,
    };
    const response: any = await axios.post(`${BASE_URL}/tenant-admin/add-update`, {
      operator_id: operator_id,
      ...requestBody,
    }, {
      headers,
    });


    return response;
  } catch (error: any) {
    throw new Error('Failed to add/update admin: ' + error.message);
  }
};

export const fetchItemDetails = async (tenant: any,) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${getBearerToken()}`,
  };
  try {
    const response: any = await axios.get(`${BASE_URL}/tenant-admin/get-operator-details?id=${tenant}`, {
      headers,
    });


    return response.data;

  } catch (error) {
    console.error('Error fetching item details:', error);
    throw error;
  }
};

export const generateToken = async (operator_id: number, token_expiry_date: any) => {
  try {

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`,
    };
    const response: any = await axios.post(`${BASE_URL}/add-operator-token`, {
      operator_id,
      token_expiry_date,

    }, {
      headers,

    });

    return response;
  } catch (error: any) {
    throw new Error('Failed to add/update admin: ' + error.message);
  }
};

export const createSubAdmin = async (requestBody: any, id: any | null) => {
  try {

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`,
    };
    const response: any = await axios.post(`${BASE_URL}/addUpdate-subAdmin/${id ? id : ""}`, {
      ...requestBody,
    }, {
      headers,
    });
    return response
  } catch (error: any) {
    throw new Error(error.message);
  }
}

export const createPermission = async (requestBody: any) => {

  try {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getBearerToken()}`,
    };

    const response = await axios.post(`${BASE_URL}/updateSubAdminPermission`, requestBody, { headers });

    return response;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchSubAdmin = async () => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }
    const apiurl = `${BASE_URL}/all-subadmins`;
    const response = await axios.get(apiurl, { headers });
    return response;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const fetchAdmin = async (id: any) => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }
    const apiurl = `${BASE_URL}/subadmins/${id}`;
    const response = await axios.get(apiurl, { headers });
    return response;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const deleteSubadmin = async (id: number) => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }
    const apiurl = `${BASE_URL}/delete-subadmin/${id}`;
    const response = await axios.get(apiurl, { headers });
    return response;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const deleteIp = async (id: number) => {
  try {
    const headers = getHeaders();
    if (!headers || !headers.Authorization) {
      throw new Error('Bearer token not available');
    }
    const apiurl = `${BASE_URL}/ipdelete/${id}`;
    const response = await axios.delete(apiurl, { headers });
    return response;

  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};