import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Table, Row, Col, } from "reactstrap";
import Flatpickr from "react-flatpickr";
import { PermissionHelper } from "Routes/helper"
import { useNavigate } from "react-router-dom";
import { fetchPlayerReport, Playerreport, playerReportFilter, operatorsForReport, operatorsListReport, fetchGameProvider, vendorsList /*fetchItemDetails*/ } from "Services/Api";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import "../OperatorTenant/Addoperator.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';


const PlayerReport = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const exchangeRateEURtoINR = 87.50;
  const [playerReport, setPlayerReport] = useState<Playerreport[]>([]);
  const [operatorListReports, setOperatorListReports] = useState<operatorsListReport[]>([]);
  const [vendorsListReports, setVendorsListReports] = useState<vendorsList[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedValueOp, setSelectedValueOp] = useState('all');
  const [selectedValueVen, setSelectedValueVen] = useState('all');
  const today = new Date();
  const formattedToday = today.toLocaleDateString("en-GB", { day: 'numeric', month: 'short', year: 'numeric' });
  const [selectedDates, setSelectedDates] = useState([formattedToday, formattedToday]);

  const handleSubmit = async () => {
    const selectedOp = selectedValueOp;
    const selectedVen = selectedValueVen;
    const selDates = selectedDates;
    const selDatesArray = selDates[0].split("/");

    if (selDates.length < 2) {
      toast.error("Please select date"); return false;
    }
    const arrayLength = selDatesArray.length;

    if (arrayLength <= 1) {
      toast.error("Please select date"); return false;
    }
    const convertedDates = selDates.map(date => {
      const [day, month, year] = date.split('/');
      return `${year}-${month}-${day}`;
    });
    const playerReportFilterRes = await playerReportFilter(selectedOp, selectedVen, convertedDates);
    if (playerReportFilterRes && playerReportFilterRes && Array.isArray(playerReportFilterRes)) {
      setPlayerReport(playerReportFilterRes);
      console.log("PlayerReport" + playerReport);
    } else {
      console.error('Invalid data format received:', playerReportFilterRes);
    }
  };
  const handleChangeOp = (event: any) => {
    setSelectedValueOp(event.target.value);
  };
  const handleChangeVen = (event: any) => {
    setSelectedValueVen(event.target.value);
  };

  const fetchData = async () => {
    try {
      const permission = PermissionHelper.PermissionChecker("operator_tenants.view");
      if (permission) {
        const apiResponse = await fetchPlayerReport();

        if (apiResponse && apiResponse && Array.isArray(apiResponse)) {
          setPlayerReport(apiResponse);
        } else {
          console.error('Invalid data format received:', apiResponse);
        }
      } else {
        toast.error("You don't have permission to view this page");
      }
      const apiResponsesOperators = await operatorsForReport();

      if (apiResponsesOperators && apiResponsesOperators && Array.isArray(apiResponsesOperators)) {
        setOperatorListReports(apiResponsesOperators);
      } else {
        console.error('Invalid data format received:', apiResponsesOperators);
      }
      const apiResponsesVendors = await fetchGameProvider();

      if (apiResponsesVendors && apiResponsesVendors && Array.isArray(apiResponsesVendors)) {
        setVendorsListReports(apiResponsesVendors);
      } else {
        console.error('Invalid data format received:', apiResponsesVendors);
      }




    } catch (error: any) {
      console.error('Error fetching data:', error);

      if (error === 'Request failed with status code 401') {
        navigate('/login');
        toast.error("Token expired. Please login again.")


      } else {

      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  document.title = t("Player Report");

  return (
    <React.Fragment>
      <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
        {PermissionHelper.PermissionChecker("reports.player_report") &&

          <div className="page-content">
            <Container fluid>
              <BreadCrumb title={t("Player Report")} pageTitle={t("Report")} />

              <div className="border border-primary p-3">
                <Row className="align-items-center mb-3">
                  <Col sm={12} lg={4}  className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                    <select
                      defaultValue={selectedValueOp}
                      onChange={handleChangeOp}
                      className="form-select form-select-lg"
                      aria-label=".form-select-lg example"
                    >
                      <option value="all">{t('All Tenants')}</option>
                      {operatorListReports.map(operator => (
                        <option key={operator.id} value={operator.id}>
                          {operator.operatorname}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={12} lg={4}   className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                    <select
                      defaultValue={selectedValueVen}
                      onChange={handleChangeVen}
                      className="form-select form-select-lg"
                      aria-label=".form-select-lg example"
                    >
                      <option value="all">{t('All Game Provider')}</option>
                      {vendorsListReports.map(gp => (
                        <option key={gp.id} value={gp.id}>
                          {gp.vendor_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={12} lg={4} xs={12}  className="mt-3 mb-3 mt-xl-0 mb-xl-0"  >
                    <div className="input-group">
                      <Flatpickr
                        className="form-control form-control-lg border-0 dash-filter-picker shadow"
                        options={{
                          mode: "range",
                          dateFormat: "d M, Y",
                          defaultDate: selectedDates,
                          onChange: (selectedDates) => setSelectedDates(selectedDates.map(date => date.toLocaleDateString())),
                        }}
                      />
                      <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-center">
                  <Col sm={12} md={{ size: 4, offset: 8 }}>
                    <div className="d-flex justify-content-end gap-2">
                      <button className="btn btn-primary" onClick={handleSubmit}>{t('Submit')}</button>
                      <button className="btn btn-warning">{t('Reset Filter')}</button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="table-responsive mt-5">
                <Table className="table-striped table-nowrap align-middle mb-0">
                  <thead>
                    <tr className="text-center">

                      <th scope="col">{t('Player ID')}</th>
                      <th scope="col">{t('Operator Name')}</th>
                      <th scope="col">{t('Username')} </th>
                      <th scope="col">GGR - EUR</th>
                      <th scope="col">GGR - INR</th>
                      <th scope="col">{t('Total Balance')} </th>
                      <th scope="col">{t('Currency')}</th>
                      <th scope="col" >{t('Total Bets')}</th>
                      <th scope="col">{t('Total Bet Amount')}</th>
                      <th scope="col">{t('Creation Date')}</th>
                      <th scope="col">{t('Last Login Date')}</th>
                      <th scope="col">{t('Status')}</th>



                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (Array.from({ length: playerReport.length || 10 }).map((_, index) => (
                      <tr key={index} className="text-center">
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                        <td><Skeleton height={30} /></td>
                      </tr>
                    ))
                    ) : (
                      playerReport && playerReport.length > 0 ? (
                        playerReport.map((playerRep) => (
                          <tr className="text-center" key={playerRep.player_id}>
                            <td>{playerRep.player_id}</td>
                            <td>{playerRep.operatorname}</td>
                            <td>{playerRep.username}</td>
                            <td>{playerRep.user_ggr != null ? playerRep.user_ggr.toFixed(2) : "0.00"}</td>
                            <td>{playerRep.user_ggr != null ? (playerRep.user_ggr * exchangeRateEURtoINR).toFixed(2) + " INR" : "0.00 INR"}</td>
                            <td>{playerRep.total_balance != null ? playerRep.total_balance : "0.00"}</td>
                            <td>{'EUR'}</td>
                            <td>{playerRep.total_bets}</td>
                            <td>{playerRep.total_bet_amount}</td>
                            <td>{new Date(playerRep.bet_created_at).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</td>
                            <td>{new Date(playerRep.bet_created_at).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' })}</td>
                            <td>Active</td>
                          </tr>
                        ))
                      ) : (
                        <tr className="text-center">
                          <td colSpan={16}><h3><b> <tr className="text-center">
                            <td colSpan={16}>No Data in this Report</td>
                          </tr></b></h3></td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </div>

            </Container>
          </div>
        }

        {!PermissionHelper.PermissionChecker("reports.player_report") &&
          <div className="page-content">
            <Container fluid>
              <BreadCrumb title="Player Report" pageTitle="Report" />
              <div className="text-center">
                <h4>You do not have permission to view this page</h4>
              </div>
            </Container>
          </div>
        }
      </SkeletonTheme>
    </React.Fragment>
  );
};

export default PlayerReport;
