import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Table, Alert, Col, Row } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { GpOperators } from "Services/Api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton, { SkeletonTheme, } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';




const GameProviderDetail = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const vendorName: any = searchParams.get('name');
    let vendorNameStr = vendorName.replace('%20', "");
    const providerId: any = searchParams.get('id');
    document.title = `View ${vendorNameStr}`;

    const [operatorData, setOperatorData] = useState<[]>([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            try {

                const response = await GpOperators(providerId);
                setOperatorData(response.data);
            } catch (error) {
                console.error('Error fetching brand list:', error);
                if (error === 'Request failed with status code 401') {
                    navigate('/login');
                    toast.error("Token expired. Please login again.")
                } else {

                }
            }
            finally {
                setLoading(false);
            }
        };


        fetchData();

    }, [providerId]);


    return (
        <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title={`View ${vendorNameStr}`} pageTitle={t("Game Provider" )}/>
                        <Row>
                            <Col lg={12}>
                                {loading ? (
                                    <Skeleton height={80} />
                                ) : (
                                    <Alert color="primary" className="d-flex flex-column">
                                        <div className="d-flex justify-content-start align-items-center mb-2">
                                            <strong className="mb-0 mr-2">{t('Game Company')} :  &nbsp;</strong>
                                            <strong className="mb-0">{vendorNameStr}</strong>
                                        </div>
                                        <div className="d-flex justify-content-start align-items-center mt-2">
                                            <strong className="mb-0 mr-2">{t('Game Play Type')} : &nbsp; </strong>
                                            <strong className="mb-0">iFrame</strong>
                                        </div>
                                    </Alert>
                                )}
                            </Col>
                        </Row>
                        <div className="mt-3 mb-3">
                            <div>
                                <Row className="align-items-center">
                                    <Col lg={12}>
                                        <div className="d-flex justify-content-end align-item-center">
                                            {loading ? (
                                                <Skeleton width={100} height={40} />
                                            ) : (
                                                <Link to="/Game-provider"><button className="btn btn-primary"> <i className=" ri-arrow-left-line"></i>{t('Back')}</button></Link>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="table-responsive">

                            <Table className="table-striped table-nowrap align-middle mb-0">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col">{t('Operator Using GP')}</th>
                                        <th scope="col">{t('Rounds Played')}</th>
                                        <th scope="col">{t('Player Count')}</th>
                                        <th scope="col">GGR</th>
                                        <th scope="col">{t('Action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        Array.from({ length: operatorData.length || 2 }).map((_, index) => (
                                            <tr key={index} className="text-center">
                                                <td><Skeleton height={30} /></td>
                                                <td><Skeleton height={30} /></td>
                                                <td><Skeleton height={30} /></td>
                                                <td><Skeleton height={30} /></td>
                                                <td><Skeleton height={30} /></td>
                                            </tr>
                                        ))
                                    ) : (
                                        operatorData && operatorData.length > 0 ? (
                                            operatorData
                                                .filter((operator: any) => operator.id !== undefined && operator.id !== null)
                                                .map((operator: any) => (
                                                    <tr className="text-center" key={operator.id}>
                                                        <td>{operator.operatorname}</td>
                                                        <td>{operator.roundid_count}</td>
                                                        <td>{operator.player_count}</td>
                                                        <td>{operator.total_ggr !== null ? operator.total_ggr : '0.00'} %</td>

                                                        <td>
                                                            <Link to={`/Game-provider-Operator/?name=${(vendorNameStr)}&id=${operator.id}&providerId=${providerId}`}>
                                                                <FeatherIcon onClick={() => navigate(`/Game-provider-detail/name=${vendorNameStr}&id=${operator.id}`)} icon="eye" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))
                                        ) : (
                                            <strong >No Data Available</strong>
                                        )
                                    )}
                                </tbody>
                            </Table>

                        </div>


                    </Container>
                </div>
            </React.Fragment>
        </SkeletonTheme>
    );
};

export default GameProviderDetail;
