import React, { useState,useEffect } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Alert, Col, Row, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { fetchOperatorTenants, Tenant } from "Services/Api";


const ManageProvider = () => {
    const [selectedValue, setSelectedValue] = useState('initialValue');

    const [tenants, setTenants] = useState<Tenant[]>([]);


    document.title = "Game Provider | Manage Provider";

    useEffect(() => {
        fetchData()

    }, []);
    const fetchData = async () => {
        try {
            const apiResponse = await fetchOperatorTenants();

            if (apiResponse && apiResponse.data && Array.isArray(apiResponse.data)) {
                setTenants(apiResponse.data);

            } else {
                console.error('Invalid data format received:', apiResponse);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Manage Provider" pageTitle="Game Provider" />
                    <Row>
                        <Col lg={12}>
                            <Alert color="primary" className="d-flex flex-column">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center mt-2">
                                        <strong className="mb-0">Game URL :</strong>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <strong className="mb-0">http://www.example.com/</strong>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <strong className="mb-0"></strong>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center mt-2">
                                        <strong className="mb-0">Game Key:</strong>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <strong className="mb-0">AwsHjkjsQQU</strong>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <p className="mb-0"></p>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center mt-2">
                                        <strong className="mb-0">Game Company:</strong>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <strong className="mb-0">Venum</strong>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <p className="mb-0"></p>
                                    </div>

                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center mt-2">
                                        <strong className="mb-0">Game Play Type:</strong>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <strong className="mb-0">iFrame </strong>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <strong className="mb-0"></strong>
                                    </div>
                                </div>
                            </Alert>
                        </Col>
                    </Row>
                    <Row className="align-items-center .border-dark">
                        <Col sm={4} lg={4} md={4} className=" align-items-end">

                            <strong>Select Operator for Managing Games for Venum</strong>
                        </Col>
                        <Col xs={6} lg={4} md={4} className=" align-items-end">
                        <select
                                        value={selectedValue}
                                        onChange={(e) => setSelectedValue(e.target.value)}
                                        className="form-select form-select-lg"
                                        aria-label=".form-select-lg example"
                                    >
                                        <option value="value1">ALL Operators</option>
                                        {tenants.map((tenant) => (
                                            <option key={tenant.operatorname} value={tenant.operatorname}>
                                                {tenant.operatorname}
                                            </option>
                                        ))}
                                    </select>
                        </Col>
                        <Col xs={4} lg={4} md={2} className=" align-items-end">
                            <div className=" d-flex justify-content-end align-item-center">
                                <Link to="/Game-provider-detail/:vendor_name"><button className="btn btn-primary"><i className=" ri-arrow-left-line"></i>Back</button></Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center .border-dark mt-3">
                        <Col sm={4} lg={4} md={4} className=" align-items-end">

                            <strong>Select a Hub Aggregator (If applicable)</strong>
                        </Col>
                        <Col sm={4} lg={4} md={4} className=" align-items-end">
                            <select defaultValue={selectedValue} className="form-select form-select-lg " aria-label=".form-select-lg example">
                                <option value="value1">Venum</option>
                                <option value="value2">Relax</option>
                                <option value="value3">Betconstruct</option>
                                <option value="value4">Slotmatrix</option>
                            </select>
                        </Col>
                        <Col sm={4} lg={4} md={4} className=" align-items-start">
                            <div className=" d-flex justify-content-start align-item-start">
                                <div className="form-check mb-3">
                                    <Input className="form-check-input" type="checkbox" id="formCheck6" defaultChecked />
                                    <Label className="form-check-label" for="formCheck6">
                                        Select to Enable VPN
                                    </Label>
                                </div>

                            </div>
                        </Col>

                    </Row>
                    <Row className="align-items-center .border-dark mt-3">
                        <Col sm={4} lg={4} md={4} className=" align-items-end">

                            <strong>Select a Brand (If applicable)</strong>
                        </Col>
                        <Col sm={4} lg={4} md={4} className=" align-items-end">
                            <select defaultValue={selectedValue} className="form-select form-select-lg " aria-label=".form-select-lg example">
                                <option value="value1">Select Brand</option>
                                <option value="value2">NetEnt</option>
                                <option value="value3">Progmatic</option>
                                <option value="value4">Three</option>
                            </select>
                        </Col>
                        <Col sm={4} lg={4} md={4} className="align-items-end">
                            <div className="d-flex flex-column align-items-start">
                                <div className="form-check ">
                                    <Input className="form-check-input" type="checkbox" id="formCheck1" defaultChecked />
                                    <Label className="form-check-label" for="formCheck1">
                                        Select to Enable VPN
                                    </Label>
                                </div>
                                <div className="form-check">
                                    <Input className="form-check-input" type="checkbox" id="formCheck2" defaultChecked />
                                    <Label className="form-check-label" for="formCheck2">
                                    Select to Block this for All Operators
                                    </Label>
                                </div>
                                <div className="form-check">
                                    <Input className="form-check-input" type="checkbox" id="formCheck2" defaultChecked />
                                    <Label className="form-check-label" for="formCheck2">
                                    Select to Block this for Only this Operators
                                    </Label>
                                </div>
                            </div>
                        </Col>

                    </Row>
                    <Row className="align-items-center .border-dark mt-3">
                        <Col  lg={4} md={3} className=" align-items-end">

                            <strong>Select the Games you Want to show</strong>
                        </Col>
                        <Col lg={4} md={3} xs={4}>
                            <div>

                                <div>
                                    <div className="form-check mb-3 mt-5">
                                        <Input className="form-check-input" type="checkbox" id="formCheck6" defaultChecked />
                                        <Label className="form-check-label" for="formCheck6">
                                            Game Name
                                        </Label>
                                    </div>
                                    <div className="form-check form-check-secondary mb-3">
                                        <Input className="form-check-input" type="checkbox" id="formCheck7" defaultChecked />
                                        <Label className="form-check-label" for="formCheck7">
                                            Game Name
                                        </Label>
                                    </div>
                                    <div className="form-check form-check-success mb-3">
                                        <Input className="form-check-input" type="checkbox" id="formCheck8" defaultChecked />
                                        <Label className="form-check-label" for="formCheck8">
                                            Game Name
                                        </Label>
                                    </div>
                                    <div className="form-check form-check-warning mb-3">
                                        <Input className="form-check-input" type="checkbox" id="formCheck9" defaultChecked />
                                        <Label className="form-check-label" for="formCheck9">
                                            Game Name
                                        </Label>
                                    </div>
                                    <div className="form-check form-check-danger mb-3">
                                        <Input className="form-check-input" type="checkbox" id="formCheck10" defaultChecked />
                                        <Label className="form-check-label" for="formCheck10">
                                            Game Name
                                        </Label>
                                    </div>
                                    <div className="form-check form-check-info mb-3">
                                        <Input className="form-check-input" type="checkbox" id="formCheck11" defaultChecked />
                                        <Label className="form-check-label" for="formCheck11">
                                            Game Name
                                        </Label>
                                    </div>
                                    <div className="form-check form-check-dark">
                                        <Input className="form-check-input" type="checkbox" id="formCheck12" defaultChecked />
                                        <Label className="form-check-label" for="formCheck12">
                                            Game Name
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={8}md={6} lg={4}>
                            <div>

                                <div>
                                    <div className="form-check mb-3 mt-5">
                                        <Input className="form-check-input" type="checkbox" id="formCheck6" defaultChecked />
                                        <Label className="form-check-label" for="formCheck6">
                                            Select to Block for All Operators
                                        </Label>
                                    </div>
                                    <div className="form-check form-check-secondary mb-3">
                                        <Input className="form-check-input" type="checkbox" id="formCheck7" defaultChecked />
                                        <Label className="form-check-label" for="formCheck7">
                                            Select to Block for All Operators
                                        </Label>
                                    </div>
                                    <div className="form-check form-check-success mb-3">
                                        <Input className="form-check-input" type="checkbox" id="formCheck8" defaultChecked />
                                        <Label className="form-check-label" for="formCheck8">
                                            Select to Block for All Operators
                                        </Label>
                                    </div>
                                    <div className="form-check form-check-warning mb-3">
                                        <Input className="form-check-input" type="checkbox" id="formCheck9" defaultChecked />
                                        <Label className="form-check-label" for="formCheck9">
                                            Select to Block for All Operators
                                        </Label>
                                    </div>
                                    <div className="form-check form-check-danger mb-3">
                                        <Input className="form-check-input" type="checkbox" id="formCheck10" defaultChecked />
                                        <Label className="form-check-label" for="formCheck10">
                                            Select to Block for All Operators
                                        </Label>
                                    </div>
                                    <div className="form-check form-check-info mb-3">
                                        <Input className="form-check-input" type="checkbox" id="formCheck11" defaultChecked />
                                        <Label className="form-check-label" for="formCheck11">
                                            Select to Block for All Operators
                                        </Label>
                                    </div>
                                    <div className="form-check form-check-dark">
                                        <Input className="form-check-input" type="checkbox" id="formCheck12" defaultChecked />
                                        <Label className="form-check-label" for="formCheck12">
                                            Select to Block for All Operators
                                        </Label>
                                    </div>
                                </div>
                            </div>
                        </Col>



                    </Row>


                </Container>
            </div>
        </React.Fragment>
    );
};

export default ManageProvider;
