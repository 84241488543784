import React from 'react';
import { Col, Row } from 'reactstrap';

interface BreadCrumbProps {
    title: string;
    pageTitle : string;
}

const BreadCrumb = ({ title, pageTitle } : BreadCrumbProps) => {
    return (
        <React.Fragment>
            <Row>
            <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item active"style={{ fontWeight: 'bold', fontSize: '15px',  }}>{pageTitle}</li>
                                <li className="breadcrumb-item "style={{ fontWeight: 'bold', fontSize: '15px',  }}>{title}</li>
                            </ol>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;