import React, { useEffect, useState } from "react";
import { Container, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Table } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { updateTenantStatus, Tenant, fetchOperatorTenantswithpagination } from "Services/Api";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import "../OperatorTenant/Addoperator.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PermissionHelper } from "Routes/helper";
import { useTranslation } from 'react-i18next';

const OperatorTenants = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const fetchData = async () => {
    try {
      const permission = PermissionHelper.PermissionChecker("operator_tenants.view");
      if (permission) {
        const response = await fetchOperatorTenantswithpagination(currentPage);
        if (response && response.data && Array.isArray(response.data)) {
          const totalPages = Number(new URL(response.links.last_page_url).searchParams.get('page'));
          setTotalPages(totalPages);
          setTenants(response.data);

        } else {
          console.error('Invalid data format received:', response);
        }
      } else {
        toast.error("You don't have permission to view this page");
      }

    } catch (error: any) {
      console.error('Error fetching data:', error);

      if (error === 'Request failed with status code 401') {
        navigate('/login');
        toast.error("Token expired. Please login again.")


      } else {

      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const handlePageClick = async (page: number) => {
    setCurrentPage(page);
    await fetchData();
  };
  const ITEMS_PER_PAGE = 10;

  const getStatusText = (status: number): string => {
    return status === 0 ? "InActive" : "Active";
  };

  const getStatusColor = (status: number): string => {
    return status === 0 ? "badge bg-danger" : "badge bg-success";
  };


  const toggleStatus = async (id: number) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success ",
        cancelButton: "btn btn-danger me-2"
      },
      buttonsStyling: false
    });

    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You want to change status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, change status!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true
    });

    if (result.isConfirmed) {
      try {
        const updatedTenants = tenants.map((tenant) => {
          if (tenant.id === id) {
            return {
              ...tenant,
              status: tenant.status === 0 ? 1 : 0,
            };
          }
          return tenant;
        });

        setTenants(updatedTenants);

        const apiResponse = await updateTenantStatus(id, updatedTenants.find((tenant) => tenant.id === id)?.status || 0);

        if (apiResponse && apiResponse.success) {
          await Swal.fire({
            title: "Status Changed!",
            text: "Operator status has been updated.",
            icon: "success"
          });
        } else {
          console.error('Failed to update status:', apiResponse);
        }
      } catch (error) {
        console.error('Error updating status:', error);
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      await Swal.fire({
        title: "Cancelled",
        text: "Cancelled.",
        icon: "error"
      });
    }
  };
  document.title = t('Operator Tenants');

  return (
    <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Operator Listing")} pageTitle={t("Operator")} />
            <div className="d-flex align-items-end justify-content-end mb-4">
              {
                PermissionHelper.PermissionChecker("operator_tenants.add") ? (
                  <Link to="/add-operator">
                    <button className="btn btn-primary">{t('Add Operator')}</button>
                  </Link>
                ) : null
              }
            </div>
            <div className="table-responsive">
              <Table className="table-striped table-nowrap align-middle mb-0">
                <thead>
                  <tr className="text-center">
                    <th scope="col">{t('Operator Name')}</th>
                    <th scope="col">{t('Registered On')}</th>
                    <th scope="col">{('Player Count')}</th>
                    <th scope="col">GP No</th>
                    <th scope="col">GGR</th>
                    <th scope="col">NGR</th>
                    <th scope="col">{t('Status')}</th>
                    <th scope="col">{t('Action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (Array.from({ length: tenants.length || 10 }).map((_, index) => (
                    <tr key={index} className="text-center">
                      <td><Skeleton height={30} /></td>
                      <td><Skeleton height={30} /></td>
                      <td><Skeleton height={30} /></td>
                      <td><Skeleton height={30} /></td>
                      <td><Skeleton height={30} /></td>
                      <td><Skeleton height={30} /></td>
                      <td><Skeleton height={30} /></td>
                      <td><Skeleton height={30} /></td>
                    </tr>
                  ))
                  ) : (
                    tenants && tenants.length > 0 ? (
                      tenants.map((tenant) => (
                        <tr className="text-center" key={tenant.id}>
                          <td>{tenant.operatorname}</td>
                          <td>{new Date(tenant.created_at).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</td>
                          <td>{tenant.player_count ? tenant.player_count : "0"}</td>
                          <td>
                            <Link to={`/add-operator/?id=${tenant.id}&tab=2`}>
                              {tenant.vendor_count}
                            </Link>
                          </td>

                          <td>{tenant.ggr_percent ? tenant.ggr_percent : "--"} %</td>
                          <td>{tenant.ngr_percent ? tenant.ngr_percent : "--"} %</td>
                          <td>
                            <span
                              className={`rounded p-1 ${getStatusColor(tenant.status)} text-white `}
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                if (PermissionHelper.PermissionChecker("operator_tenants.status_change")) {
                                  toggleStatus(tenant.id);
                                } else {
                                  toast.error("You don't have permission to change status");
                                }
                              }}
                            >
                              {getStatusText(tenant.status)}
                            </span>
                          </td>
                          <td>
                            {
                              PermissionHelper.PermissionChecker("operator_tenants.edit") ? (
                                <Link to={`/add-operator/?id=${tenant.id}`}>
                                  <FeatherIcon icon="edit-2" style={{ cursor: "pointer" }} />
                                </Link>
                              ) : <FeatherIcon onClick={() => toast.error("You don't have permission to edit")} icon="edit-2" style={{ cursor: "pointer" }} />
                            }
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr></tr>
                    )
                  )}
                </tbody>
              </Table>
              <div className="pagination-wrapper d-flex justify-content-end gap-2 mt-3">
                <p style={{ marginTop: "26px" }} className='text-muted'>{t('Page')} {currentPage} of {totalPages}</p>
                <Pagination className="pagination-container">
                  <PaginationItem className="pagination-item" disabled={currentPage === 1}>
                    <PaginationLink className="pagination-link" previous onClick={() => handlePageClick(currentPage - 1)} style={{ padding: "0.5rem 0.75rem", minWidth: "40px", textAlign: "center" }}>
                      <i className=" ri-arrow-left-line"></i>
                    </PaginationLink>
                  </PaginationItem>
                  {currentPage > 1 && (
                    <PaginationItem className="pagination-item">
                      <PaginationLink className="pagination-link" onClick={() => handlePageClick(currentPage - 1)} style={{ padding: "0.5rem 0.75rem", minWidth: "40px", textAlign: "center" }}>
                        {currentPage - 1}
                      </PaginationLink>
                    </PaginationItem>
                  )}
                  <PaginationItem className="pagination-item active">
                    <PaginationLink className="pagination-link" style={{ padding: "0.5rem 0.75rem", minWidth: "40px", textAlign: "center" }}>
                      {currentPage}
                    </PaginationLink>
                  </PaginationItem>
                  {currentPage < totalPages && (
                    <PaginationItem className="pagination-item">
                      <PaginationLink className="pagination-link" onClick={() => handlePageClick(currentPage + 1)} style={{ padding: "0.5rem 0.75rem", minWidth: "40px", textAlign: "center" }}>
                        {currentPage + 1}
                      </PaginationLink>
                    </PaginationItem>
                  )}
                  <PaginationItem className={`pagination-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <PaginationLink className="pagination-link" next onClick={() => handlePageClick(currentPage + 1)} style={{ padding: "0.5rem 0.75rem", minWidth: "40px", textAlign: "center" }}>
                      <i className=" ri-arrow-right-line"></i>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>

              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    </SkeletonTheme>
  );
};

export default OperatorTenants;
