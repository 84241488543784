import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Table, Alert, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { fetchItemDetails, GpOperators } from "Services/Api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Skeleton, { SkeletonTheme, } from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';


interface Game {
    gameName: string;
    name:string;

}
interface BrandGger {
    ggr_percent: string;

}
interface Tenant {
    id: any;

}
interface OperatorData {
    games: Game[];
    brands_gger: BrandGger[];
}
const GameProviderOperator = () => {
    const {t} = useTranslation ()
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const operatorId: any = searchParams.get('id');
    const [selectedValue, setSelectedValue] = useState(operatorId);
    const [alltenants, setAllTenants] = useState<Tenant[]>([]);
    const [selectedOperatorData, setSelectedOperatorData] = useState<OperatorData | null>(null);
    const [searchInput, setSearchInput] = useState('');
    const [loadingData, setLoadingData] = useState(true);
    const vendorName: any = searchParams.get("name");
    let vendorNameStr = vendorName.replace('%20', "");
    const providerId: any = searchParams.get("providerId");
    const navigate = useNavigate();
    document.title = `View ${vendorNameStr}`;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoadingData(true);

                const response = await fetchItemDetails(operatorId);
                setSelectedOperatorData(response);
                setSelectedValue(operatorId);
            } catch (error) {
                console.error('Error fetching  list:', error);
                if (error === 'Request failed with status code 401') {
                    navigate('/login');
                    toast.error("Token expired. Please login again.")
                } else {

                }
            } finally {
                setLoadingData(false);
            }
        };

        const Alloperator = async () => {
            try {
                setLoadingData(true);

                const response = await GpOperators(providerId);
                setAllTenants(response.data)
            } catch (error) {
                console.error('Error fetching brand list:', error);
                if (error === 'Request failed with status code 401') {
                    navigate('/login');
                    toast.error("Token expired. Please login again.")
                } else {

                }
            } finally {
                setLoadingData(false);
            }
        };

        Alloperator();
        fetchData();
    }, [operatorId]);
    selectedOperatorData?.games.filter(game => game.name.toLowerCase().includes(searchInput.toLowerCase())).map((game: any, index: any) => (
        <div key={index}>{game.name}</div>
      ))
    const getRecords = async (selectedOperatorId: string) => {
        try {
            setLoadingData(true);
            const operatorDetails = await fetchItemDetails(selectedOperatorId);
            setSelectedOperatorData(operatorDetails);
            setSelectedValue(selectedOperatorId);
        } catch (error) {
            console.error('Error fetching selected operator data:', error);
        } finally {
            setLoadingData(false);
        }
    };

    return (
        <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title={`View ${vendorNameStr}`} pageTitle={t("Game Provider" )}/>
                        <Row>
                            <Col lg={12}>
                                {loadingData ? (
                                    <Skeleton height={80} />
                                ) : (
                                    alltenants.length > 0 && (
                                        <Alert color="primary" className="d-flex flex-column">
                                            <div className="d-flex justify-content-start align-items-center mb-2">
                                                <strong className="mb-0 mr-2">{t('Game Company')} : &nbsp;</strong>
                                                <strong className="mb-0">{vendorNameStr}</strong>
                                            </div>
                                            <div className="d-flex justify-content-start align-items-center mt-2">
                                                <strong className="mb-0 mr-2">{t('Game Play Type')} : &nbsp; </strong>
                                                <strong className="mb-0">iFrame</strong>
                                            </div>
                                        </Alert>
                                    )
                                )}
                            </Col>
                        </Row>
                        <div className="mt-3 mb-3">
                            <div>
                                <Row className="align-items-center">
                                    <Col lg={4} md={4} >
                                        {loadingData ? (
                                            <Skeleton height={40} />
                                        ) : (
                                            alltenants.length > 0 && (
                                                <strong>{t('Select Operator for showing GGR by Game')}</strong>
                                            )
                                        )}
                                    </Col>
                                    <Col lg={3} md={3} className="mt-3 mb-3 mt-xl-0 mb-xl-0" >
                                        {loadingData ? (
                                            <Skeleton height={40} />
                                        ) : (
                                            alltenants.length > 0 && (
                                                <select
                                                    value={selectedValue}
                                                    defaultValue={selectedValue}
                                                    onChange={(e) => getRecords(e.target.value)}
                                                    className="form-select form-select-lg"
                                                    aria-label=".form-select-lg example"
                                                >
                                                    {alltenants.map((tenant: any) => (
                                                        <option key={tenant.operatorname} value={tenant.id}>
                                                            {tenant.operatorname}
                                                        </option>
                                                    ))}
                                                </select>
                                            )
                                        )}
                                    </Col>
                                    <Col lg={3} md={3} className="mt-3 mb-3 mt-xl-0 mb-xl-0" >
                                        <input
                                            type="text"
                                            value={searchInput}
                                            onChange={(e) => setSearchInput(e.target.value)}
                                            placeholder={t("Search Games")}
                                            className="form-control form-control-lg"
                                        />
                                    </Col>
                                    <Col lg={2} md={2} >
                                        <div className="d-flex justify-content-end align-item-center">
                                            {loadingData ? (
                                                <Skeleton width={100} height={40} />
                                            ) : (
                                                alltenants.length > 0 && (
                                                    <Link to={`/Game-provider-detail/?name=${vendorNameStr}&id=${providerId}`}>
                                                        <button className="btn btn-primary">
                                                            <i className="ri-arrow-left-line"></i>{t('Back')}
                                                        </button>
                                                    </Link>
                                                )
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Table className="table-striped table-nowrap align-middle mb-0">
                                <thead>
                                    <tr className="text-center">
                                        <th scope="col">{t('Game Name')}</th>
                                        <th scope="col">{t('Brand Name')}</th>
                                        <th scope="col">{t('Rounds Played')}</th>
                                        <th scope="col">{t('Player Count')}</th>
                                        <th scope="col">{('GGR')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loadingData ? (
                                        Array.from({ length: 9 }).map((_, index) => (
                                            <tr key={index}>
                                                <td><Skeleton height={30} /></td>
                                                <td><Skeleton height={30} /></td>
                                                <td><Skeleton height={30} /></td>
                                                <td><Skeleton height={30} /></td>
                                                <td><Skeleton height={30} /></td>
                                            </tr>
                                        ))
                                    ) : (
                                        selectedOperatorData?.games ? (
                                            selectedOperatorData.games.filter(game => game.name.toLowerCase().includes(searchInput.toLowerCase())).length > 0 ? (
                                              selectedOperatorData.games.filter(game => game.name.toLowerCase().includes(searchInput.toLowerCase())).map((game: any, index: any)  => (

                                                    game?.name ? (
                                                        <tr key={index} className="text-center">
                                                            <td>{game.name}</td>
                                                            <td>{game.games_brands}</td>
                                                            <td className={parseFloat(game.total_rounds) > 0 ? 'text-danger' : ''}>{game.total_rounds} </td>
                                                            <td className={parseFloat(game.total_users) > 0 ? 'text-danger' : ''}>{game.total_users} </td>
                                                            <td key={index} className={parseFloat(game.game_ggr) > 0 ? 'text-danger' : ''}>{game.game_ggr}</td></tr>
                                                    ) : null
                                                ))
                                            ) : (

                                                <tr className="text-center">
                                                    <td colSpan={16}><h3><b> <tr className="text-center">
                                                        <td colSpan={16}>No Data Available</td>
                                                    </tr></b></h3></td>
                                                </tr>
                                            )
                                        ) : null
                                    )}
                                </tbody>

                            </Table>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        </SkeletonTheme>
    );
};

export default GameProviderOperator;