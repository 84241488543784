export class PermissionHelper {
    static PermissionChecker(key: string) {
        const allPermission = sessionStorage.getItem('authUser');
        const d = JSON.parse(allPermission? allPermission : '');
        if(d?.data.role === 'superadmin' ){
            return true;
        }
        if (!allPermission) {
            return false;
        }
        try {
            const parsed = JSON.parse(allPermission);
            if (!parsed.data || !parsed.data.permissions) {
                return false;
            }
            const permissions = parsed.data.permissions;
            const keys = key.split('.');
            let current = permissions;
            for (const k of keys) {
                if (typeof current !== 'object' || current[k] === undefined) {
                    return false;
                }
                current = current[k];
            }
            return current === 1; // return true if current is 1, false otherwise
        } catch (error) {
            console.error('Error parsing permissions:', error);
            return false;
        }
    }
}