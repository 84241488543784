import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/Dashboard";


//GameProvider
import GameProvider from "pages/Sidebar/GameProvider/GameProvider";
import GameProviderDetail from "pages/Sidebar/GameProvider/GameProviderDetail";
import GameProviderOperator from "pages/Sidebar/GameProvider/GameProviderOperator";
import ManageProvider from "pages/Sidebar/GameProvider/ManageProvider";
import EditOperator from "pages/Sidebar/GameProvider/EditGameprovider";

//OperatorTenant
import OperatorTenants from "pages/Sidebar/OperatorTenant/OperatorTenant";
import AddOperator from "pages/Sidebar/OperatorTenant/AddOperator";

//Reports
import GameTransaction from "pages/Sidebar/Reports/GameTransaction";
import PlayerFinancial from "pages/Sidebar/Currency/CustomCurrency";
import PlayerReport from "pages/Sidebar/Reports/PlayerReport";
import Currencies from "pages/Sidebar/Currency/Currencies";
import Yourkeys from "pages/yourkeys";
//Users
import AllUsers from "pages/Sidebar/Users/AllUsers";


//Invoice
import Invoice from "pages/Sidebar/Invoices/Invoice";



// //AuthenticationInner pages
import BasicSignIn from '../pages/AuthenticationInner/Login/BasicSignIn';
import CoverSignIn from '../pages/AuthenticationInner/Login/CoverSignIn';
// import BasicSignUp from '../pages/AuthenticationInner/Register/BasicSignUp';
// import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
// import BasicPasswReset from '../pages/AuthenticationInner/PasswordReset/BasicPasswReset';
// //pages
// import CoverPasswReset from '../pages/AuthenticationInner/PasswordReset/CoverPasswReset';
// import BasicLockScreen from '../pages/AuthenticationInner/LockScreen/BasicLockScr';
// import CoverLockScreen from '../pages/AuthenticationInner/LockScreen/CoverLockScr';
// import BasicLogout from "pages/AuthenticationInner/Logout/BasicLogout";
// import CoverLogout from '../pages/AuthenticationInner/Logout/CoverLogout';
// import BasicSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg';
// import CoverSuccessMsg from '../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg';
// import BasicTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify';
// import CoverTwosVerify from '../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify';
// import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
// import Cover404 from '../pages/AuthenticationInner/Errors/Cover404';
// import Alt404 from '../pages/AuthenticationInner/Errors/Alt404';
// import Error500 from '../pages/AuthenticationInner/Errors/Error500';

// import BasicPasswCreate from "../pages/AuthenticationInner/PasswordCreate/BasicPasswCreate";
// import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
// import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

// //APi Key
import APIKey from "../pages/APIKey/index";

// //login
import Login from "../pages/Authentication/Login";

import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";



import PrivacyPolicy from '../pages/Pages/PrivacyPolicy';
import TermsCondition from '../pages/Pages/TermsCondition';

// // User Profile
import UserProfile from "../pages/Authentication/user-profile";
import GeneratedKeys from "Admin/GeneratedKeys";
import ViewDocumentation from "Admin/ViewDocumentation";

import AddSubAdmin from "pages/Sidebar/SubAdmin/AddSubAdmin";
import EditSubAdmin from "pages/Sidebar/SubAdmin/EditSubAdmin";
import IpBlock from "pages/Sidebar/IP Blocking/IpBlocking";
import IpBlockList from "pages/Sidebar/IP Blocking/BlockedIpList";




const authProtectedRoutes = [

  { path: "/add-sub-admin" , component: <AddSubAdmin/>},
  { path: "/create-new-subadmin", component: <EditSubAdmin /> },
  { path: `/edit-sub-admin/:id`, component: <EditSubAdmin /> },
  { path: "/dashboard", component: <DashboardEcommerce /> },



  //GameProvider

  { path: "/Game-provider", component: <GameProvider /> },
  { path: "/Game-provider-detail", component: <GameProviderDetail /> },
  { path: "/Game-provider-Operator", component: <GameProviderOperator /> },
  { path: "/Manage-Provider", component: <ManageProvider /> },
  { path: "/Edit-Provider", component: <EditOperator /> },


  //operator Tenant
  { path: "/Operator-tenant", component: <OperatorTenants /> },
  { path: "/add-operator", component: <AddOperator /> },


  //Invoice
  { path: "/invoices", component: <Invoice /> },

  //IpBlock
  {path: "/ip-blocked-list", component: <IpBlockList/>},
  { path: "/ip-blocking", component: <IpBlock/> },

  //Reports
  { path: "/Game-transaction-report", component: <GameTransaction /> },
  { path: "/Custom-currency", component: <PlayerFinancial /> },
  { path: "/Player-report", component: <PlayerReport /> },
  { path: "/currencies", component: <Currencies /> },

  //Users
  { path: "/All-users", component: <AllUsers /> },
  


  //Tenant Admin

  {path: "/Generated-keys", component : <GeneratedKeys/>},
  {path: "/View-documentation", component : <ViewDocumentation/>},
  {path: "/your-keys", component : <Yourkeys/>},



  // //Api Key
  { path: "/apps-api-key", component: <APIKey /> },



  // //Pages

  // //Job pages

  { path: "/pages-privacy-policy", component: <PrivacyPolicy /> },
  { path: "/pages-terms-condition", component: <TermsCondition /> },


  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  // { path: "/register", component: <Register /> },

  // //AuthenticationInner pages
  { path: "/auth-signin-basic", component: <BasicSignIn /> },
  { path: "/auth-signin-cover", component: <CoverSignIn /> },
  // { path: "/auth-signup-basic", component: <BasicSignUp /> },
  // { path: "/auth-signup-cover", component: <CoverSignUp /> },
  // { path: "/auth-pass-reset-basic", component: <BasicPasswReset /> },
  // { path: "/auth-pass-reset-cover", component: <CoverPasswReset /> },
  // { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  // { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
  // { path: "/auth-logout-basic", component: <BasicLogout /> },
  // { path: "/auth-logout-cover", component: <CoverLogout /> },
  // { path: "/auth-success-msg-basic", component: <BasicSuccessMsg /> },
  // { path: "/auth-success-msg-cover", component: <CoverSuccessMsg /> },
  // { path: "/auth-twostep-basic", component: <BasicTwosVerify /> },
  // { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  // { path: "/auth-404-basic", component: <Basic404 /> },
  // { path: "/auth-404-cover", component: <Cover404 /> },
  // { path: "/auth-404-alt", component: <Alt404 /> },
  // { path: "/auth-500", component: <Error500 /> },
  // { path: "/auth-pass-change-basic", component: <BasicPasswCreate /> },
  // { path: "/auth-pass-change-cover", component: <CoverPasswCreate /> },
  // { path: "/auth-offline", component: <Offlinepage /> },


];

export { authProtectedRoutes, publicRoutes };