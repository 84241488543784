import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Widget from "./Dashboard";

const DashboardEcommerce = () => {
  document.title = "Dashboard";
  const [rightColumn, setRightColumn] = useState<boolean>(true);


  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };
  let Adminrole: string | null = null;
  if (!Adminrole) {
    const authUserstring = sessionStorage.getItem('authUser');
    if (authUserstring) {
      const authUser = JSON.parse(authUserstring);
      if (authUser && authUser.data.role) {
        Adminrole = authUser.data.role;
      }
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                {Adminrole === 'superadmin' || Adminrole === "subadmin" ? (
                  <>
                    <Row>
                      <Widget />

                    </Row>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardEcommerce;
