import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Table, Modal, ModalHeader, ModalBody, ModalFooter, Button, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme, } from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchCurrencylistwithpagination, Currency, addCurrencyMargin, getCurrencyMargin } from 'Services/Api';
import { useTranslation } from 'react-i18next';

const Currencies = () => {
  const { t } = useTranslation();
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [loading, setLoading] = useState(true);
  const [margin, setMargin] = useState<number>(0);
  const [currentMargin, setCurrentMargin] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetchCurrencylistwithpagination(currentPage);
      if (response && response.data) {
        setCurrencies(response.data);
        const totalPages = Number(new URL(response.links.last_page_url).searchParams.get('page'));
        setTotalPages(totalPages);
      }
      const marginResponse = await getCurrencyMargin();
      setCurrentMargin(marginResponse);

    } catch (error) {
      console.error("Error fetching data :", error);
    } finally { setLoading(false) }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage]);
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };
  const handleViewKey = () => {
    handleToggleModal();
  };

  const handleSave = async () => {
    try {
      const response = await addCurrencyMargin(margin);
      if (response && response.status === 'success') {
        toast.success('Margin added successfully!', {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        handleToggleModal();
      }
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while adding the margin.', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handlePageClick = async (page: number) => {
    setCurrentPage(page);
    await fetchData();
  };

  document.title = t(`Manage Currencies`);

  return (
    <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t("Manage Currencies")} pageTitle={t("Currencies")} />

            <div className="d-flex align-items-end justify-content-center mt-3">
              <Col md={12} lg={12} xxl={12} >
                <Card>
                  <CardBody>
                    <strong> <h4>{t('Manage Currencies')}</h4></strong>
                    <h6 className=' mt-3'>{t('Current Margin')}: {currentMargin}</h6>
                    <div className="d-flex justify-content-end align-item-center gap-2">
                      <button className='btn btn-success' onClick={() => handleViewKey()}>{t('Add Margin')}</button>
                      <Link to="/Custom-currency"><button className="btn btn-success">{t('Create New')}</button></Link>
                    </div>
                    <div className="table-responsive mt-3">
                      <Table className="table-striped table-nowrap align-middle mb-0">
                        <thead>
                          <tr className="text-center">
                            <th scope="col">ID</th>
                            <th scope="col">{t('Name')}</th>
                            <th scope="col">{t('Code')}</th>
                            <th scope="col">{t('Exchange Rate')}</th>
                          </tr>
                        </thead>
                        <tbody>

                          {loading ? (
                            Array.from({ length: currencies.length || 9 }).map((_, index) => (
                              <tr key={index} className="text-center">
                                <td><Skeleton height={30} /></td>
                                <td><Skeleton height={30} /></td>
                                <td><Skeleton height={30} /></td>
                                <td><Skeleton height={30} /></td>
                              </tr>
                            ))
                          ) : (
                            currencies && currencies.length > 0 ? (
                              currencies.map((currency) => (
                                <tr className="text-center" key={currency.id}>
                                  <td>{currency.id}</td>
                                  <td>{currency.name}</td>
                                  <td>{currency.code}</td>
                                  <td>{currency.exchange_rate}</td>
                                </tr>
                              ))
                            ) : (
                              <tr></tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                    <div className="pagination-wrapper d-flex justify-content-end gap-2 mt-3">
                      <p style={{ marginTop: "26px" }} className='text-muted'>{t('Page')} {currentPage} of {totalPages}</p>
                      <Pagination className="pagination-container">
                        <PaginationItem className="pagination-item" disabled={currentPage === 1}>
                          <PaginationLink className="pagination-link" previous onClick={() => handlePageClick(currentPage - 1)} style={{ padding: "0.5rem 0.75rem", minWidth: "40px", textAlign: "center" }}>
                            <i className=" ri-arrow-left-line"></i>
                          </PaginationLink>
                        </PaginationItem>
                        {currentPage > 1 && (
                          <PaginationItem className="pagination-item">
                            <PaginationLink className="pagination-link" onClick={() => handlePageClick(currentPage - 1)} style={{ padding: "0.5rem 0.75rem", minWidth: "40px", textAlign: "center" }}>
                              {currentPage - 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}
                        <PaginationItem className="pagination-item active">
                          <PaginationLink className="pagination-link" style={{ padding: "0.5rem 0.75rem", minWidth: "40px", textAlign: "center" }}>
                            {currentPage}
                          </PaginationLink>
                        </PaginationItem>
                        {currentPage < totalPages && (
                          <PaginationItem className="pagination-item">
                            <PaginationLink className="pagination-link" onClick={() => handlePageClick(currentPage + 1)} style={{ padding: "0.5rem 0.75rem", minWidth: "40px", textAlign: "center" }}>
                              {currentPage + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}
                        <PaginationItem className={`pagination-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                          <PaginationLink className="pagination-link" next onClick={() => handlePageClick(currentPage + 1)} style={{ padding: "0.5rem 0.75rem", minWidth: "40px", textAlign: "center" }}>
                            <i className=" ri-arrow-right-line"></i>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </div>
                    <strong> <p className='mt-5'>{t('Note - EUR is primary currency. Any conversion from currency A to currency B will consider EUR as base.')} <br /> </p></strong>
                    <p >{t('e.g. Currency A amount * Exchange Rate of Currency A = Primary Currency(EUR) amount')} <br />
                      {t('e.g. Currency A amount * (Exchange Rate of Currency A / Exchange Rate of Currency B) = Currency B Amount')}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Container>
        </div>
      </React.Fragment>
      <Modal isOpen={showModal} toggle={handleToggleModal} size="sm" style={{ maxWidth: '600px' }}>
        <ModalHeader toggle={handleToggleModal}>
          {t('Add Margin')}
        </ModalHeader>
        <ModalBody className="overflow-auto">
          <div className="form-group">
            <label htmlFor="example-text-input" className="col-form-label">{t('Enter Margin')} </label>
            <input className="form-control" onChange={e => setMargin(Number(e.target.value))} type="number" id="example-text-input" />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => { handleSave(); fetchData(); }}
          >
            {t('Save')}
          </Button>
          <Button color="danger" onClick={handleToggleModal}>
            {t('Close')}
          </Button>
        </ModalFooter>
      </Modal>

    </SkeletonTheme>

  )
}

export default Currencies