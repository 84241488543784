import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Table, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import FeatherIcon from "feather-icons-react";
import Select from "react-select"
import Swal from "sweetalert2";
import { AllUsersData, fetchGameProvider, UserBlockbyGp } from 'Services/Api';
import { UncontrolledTooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
interface Operator {
  id: number;
  operatorname: string;
}

interface User {
  guid: string;
  id: number;
  username: string;
  email: string;
  operator_id: number;
  status_id: number;
  operator: Operator;
  block_game_providers: string;
}

const AllUsers = () => {

  const { t } = useTranslation();
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<'all' | 'active' | 'blocked'>('all');
  const [title, setTitle] = useState('All Users');
  const [searchQuery, setSearchQuery] = useState('');
  const [modal, setModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<{ value: string, label: string }[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [gameProviders, setGameProviders] = useState<{ value: string, label: string }[]>([]);
  const [modalType, setModalType] = useState<'view' | 'block'>('view');

  document.title = t(title);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await AllUsersData();
      setAllUsers(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchGameProviders();
  }, []);
  const fetchGameProviders = async () => {
    try {
      const response = await fetchGameProvider();
      const options = response.map((provider: { id: number; vendor_name: string }) => ({
        value: provider.id.toString(),
        label: provider.vendor_name,
      }));
      setGameProviders([
        { value: 'select_all', label: 'Select All' },
        { value: 'none', label: 'None' },
        ...options,
      ]);
    } catch (error) {
      console.error("Error fetching game providers:", error);
    }
  };
  const toggleStatus = (id: number, type: 'view' | 'block') => {
    setSelectedUser(allUsers.find(user => user.id === id) || null);
    setModal(true);
    setModalType(type);
  };

  const handleSave = async () => {
    if (selectedUser) {
      try {
        const providerIds = selectedOptions.map(option => option.value);
        let providerIdString = '';

        if (providerIds.includes('none')) {
          providerIdString = '0';
        } else if (providerIds.includes('select_all')) {
          providerIdString = gameProviders.filter(option => option.value !== 'select_all' && option.value !== 'none').map(option => option.value).join(',');
        } else {
          providerIdString = providerIds.join(',');
        }

        const payload = {
          id: selectedUser.id,
          game_provider_ids: providerIdString,
        };

        const response = await UserBlockbyGp(payload);

        if (response.status == "success") {
          await Swal.fire({
            title: "Success!",
            text: "User providers have been updated.",
            icon: "success"
          });
          fetchData()

        } else {
          console.error('Failed to update user providers:', response);
        }
      } catch (error) {
        console.error('Error updating user providers:', error);
      } finally {
        setModal(false);
      }
    }
  };
  const getStatusColor = (block_game_providers: string | null): string => {
    return block_game_providers === "0" || block_game_providers === null ? "badge bg-danger" : "badge bg-success";
  };



  const filteredUsers = allUsers.filter((user) => {
    const matchesFilter = filter === 'all' ||
      (filter === 'active' && (user.block_game_providers === "0" || user.block_game_providers === null)) ||
      (filter === 'blocked' && user.block_game_providers !== "0" && user.block_game_providers !== null);
    const matchesSearch = user.username.toLowerCase().includes(searchQuery.toLowerCase()) || user.operator.operatorname.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesFilter && matchesSearch;
  });

  const handleFilterChange = (newFilter: 'all' | 'active' | 'blocked') => {
    setFilter(newFilter);
    const newTitle = newFilter === 'all' ? 'All Users' : newFilter === 'active' ? 'Active Users' : 'Blocked Users';
    setTitle(newTitle);
  };
  const handleSelectChange = (options: any) => {
    if (options.some((option: { value: string }) => option.value === 'select_all')) {
      setSelectedOptions(gameProviders.filter(option => option.value !== 'select_all' && option.value !== 'none'));
    } else if (options.some((option: { value: string }) => option.value === 'none')) {
      setSelectedOptions([{ value: 'none', label: 'None' }]);
    } else {
      setSelectedOptions(options);
    }
  };

  return (
    <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t(title)} pageTitle={t("Users")} />
            <div className="d-flex align-items-end justify-content-center mb-4">
              <Col xs={12} md={12} lg={12} xxl={12}>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between align-items-center mt-3 gap-2">
                      <Col xs={4}lg={3}>
                        <Input
                          type="text"
                          placeholder={t("Search by username or operatorname")}
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </Col>
                      <div className='gap-2'>
                        <Button color="primary" className="me-2" onClick={() => handleFilterChange('all')}>{t('All')}</Button>
                        <Button color="success" className="me-2" onClick={() => handleFilterChange('active')}>{t('Active')}</Button>
                        <Button color="danger" onClick={() => handleFilterChange('blocked')}>{t('Blocked')}</Button>
                      </div>
                    </div>
                    <div className="table-responsive mt-3">
                      <Table className="table-striped table-nowrap align-middle mb-0">
                        <thead>
                          <tr className="text-center">
                            <th scope="col">{t('Username')}</th>
                            <th scope="col">{t('Email')}</th>
                            <th scope="col">{t('Operatorname')}</th>
                            <th scope="col">{t('Status')}</th>
                            <th scope="col">{t('Blocked Game Provider')}</th>
                            <th scope="col">{t('Action')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            Array.from({ length: 9 }).map((_, index) => (
                              <tr key={index} className="text-center">
                                <td><Skeleton height={30} /></td>
                                <td><Skeleton height={30} /></td>
                                <td><Skeleton height={30} /></td>
                                <td><Skeleton height={30} /></td>
                                <td><Skeleton height={30} /></td>
                                <td><Skeleton height={30} /></td>
                              </tr>
                            ))
                          ) : (
                            filteredUsers.length > 0 ? (
                              filteredUsers.map((user) => (
                                <tr className="text-center" key={user.id}>
                                  <td>{user.username}</td>
                                  <td>{user.email}</td>
                                  <td>{user.operator.operatorname}</td>
                                  <td className={user.block_game_providers === "0" || user.block_game_providers === null ? 'text-success' : 'text-danger'}>
                                    {user.block_game_providers === "0" || user.block_game_providers === null ? t('Active') : t('Blocked')}
                                  </td>
                                  <td>
                                    <FeatherIcon
                                      id={`viewIcon-${user.id}`}
                                      icon="eye"
                                      style={{
                                        cursor: user.block_game_providers !== "0" && user.block_game_providers !== null ? 'pointer' : 'not-allowed',
                                        color: user.block_game_providers !== "0" && user.block_game_providers !== null ? '#gray' : '#808080'
                                      }}
                                      onClick={() => {
                                        if (user.block_game_providers !== "0" && user.block_game_providers !== null) {
                                          toggleStatus(user.id, 'view');
                                        }
                                      }}
                                    />
                                    {user.block_game_providers === "0" || user.block_game_providers === null ? (
                                      <UncontrolledTooltip placement="top" target={`viewIcon-${user.id}`}>
                                        {t('No blocked providers')}
                                      </UncontrolledTooltip>
                                    ) : null}
                                  </td>




                                  <td>
                                    <button
                                      style={{ border: 'none', background: 'transparent' }}
                                      title={user.block_game_providers === "0" || user.block_game_providers === null ? t('Block User') : t('Unblock User')}
                                      className={`${getStatusColor(user.block_game_providers)} text-white`}
                                      onClick={() => { toggleStatus(user.id, 'block') }}
                                    >
                                      {user.block_game_providers === "0" || user.block_game_providers === null ? <FeatherIcon icon="lock" style={{ cursor: "pointer" }} /> : <FeatherIcon icon="unlock" style={{ cursor: "pointer" }} />}
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr><td colSpan={5} style={{ textAlign: "center", fontWeight: "bold" }}>
                                {t('No Records Found')}
                              </td></tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </Container>
        </div>
        <Modal isOpen={modal} toggle={() => setModal(!modal)} className="modal-md modal-dialog-centered">
          <ModalHeader toggle={() => setModal(!modal)}>
            {modalType === 'view' ? `Blocked Providers for   ${selectedUser?.username}` : t('Block Provider for User')}
          </ModalHeader>
          <ModalBody>
            {modalType === 'view' ? (
              <div>
                <ul>
                  {selectedUser?.block_game_providers?.split(',').map((providerId) => {
                    const provider = gameProviders.find((gp) => gp.value === providerId);
                    return <li key={providerId}>{provider ? provider.label : 'Active'}</li>;
                  })}
                </ul>
              </div>
            ) : (
              <Select
                isMulti
                options={gameProviders}
                value={selectedOptions}
                onChange={handleSelectChange}
              />
            )}
          </ModalBody>
          {modalType === 'block' && (
            <ModalFooter>
              <Button color="success" onClick={handleSave}>{t('Save')}</Button>
              <Button color="danger" onClick={() => setModal(!modal)}>{t('Close')}</Button>
            </ModalFooter>
          )}
        </Modal>

      </React.Fragment>
    </SkeletonTheme>
  );
}

export default AllUsers;
