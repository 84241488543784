import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useNavigate } from "react-router-dom";
import classnames from "classnames";
import { Table, Col, Card, CardBody, Label, Input, Nav, NavItem, NavLink, TabContent, TabPane, Row, } from "reactstrap";
import { fetchSubAdmin, CompensateInvoice, NotCompensateInvoice, SingleOperatorInvoice, fetchOperatorTenants } from "Services/Api"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import "./Invoice.css";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

interface Admin {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    status_id: string;
}
interface Operator {
    id: number;
    operatorname: string;

}
interface Option {
    value: string;
    label: string;
}
const months: Option[] = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
];

const years: Option[] = Array.from({ length: 20 }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return { value: year.toString(), label: year.toString() };
});
const Invoice = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    document.title = t("Invoice");
    const [animationNavTab, setanimationNavTab] = useState<string>("1");
    const [admins, setAdmins] = useState<Admin[]>([]);
    const [operator, setOperator] = useState<Operator[]>([]);
    const [loading1, setLoading1] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState<{ value: number; label: string } | null>(null);
    const [selectedOperator, setSelectedOperator] = useState<{ value: number; label: string } | null>(null);
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [isSingleOptClicked, setIsSingleOptClicked] = useState(false);
    const [isSearchClickedNot, setIsSearchClickedNot] = useState(false);
    const [invoiceData, setInvoiceData] = useState<Array<{ operator_name: string; total_bet: number; total_win: number; game_provider_name: string; adjusted_ggr: number; platform: string, ggr: number ,ggr_percent:number,ngr_value: number , averageGGR : number ,month : string}>>([]);
    const [subAdminData, setSubAdminData] = useState<Array<{ game_provider_name: string; total_win: number; total_bet: number; ggr: string; adjusted_ggr: number; platform : string ; ggr_percent : number  ,ngr_value : number ,month :string}>>([]);
    const [invoiceInfo, setInvoiceInfo] = useState<{ invoiceNumber: number; dueDate: number; createdDate: number; total: number }>({ invoiceNumber: 0, dueDate: 0, createdDate: 0, total: 0 });
    const [selectedMonthTab1, setSelectedMonthTab1] = useState<{ value: number; label: string } | null>(null);
    const [selectedYearTab1, setSelectedYearTab1] = useState<{ value: number; label: string } | null>(null);
    const [selectedMonthTab2, setSelectedMonthTab2] = useState<{ value: number; label: string } | null>(null);
    const [selectedYearTab2, setSelectedYearTab2] = useState<{ value: number; label: string } | null>(null);



    const SearchCompensate = async () => {
        try {
            setLoading1(true);
            setIsSearchClicked(false);
            if (selectedAdmin) {
                const month = selectedMonthTab2 ? selectedMonthTab2.value : null;
                const year = selectedYearTab2 ? selectedYearTab2.value : null;
                const response = await CompensateInvoice(selectedAdmin.value, month , year ,);
                if (!response || response.status === 'error') {
                    const errorMessage = response ? response.errors : 'Error In Fetching Invoice !!!';
                    toast.error(errorMessage);

                } else {
                    setInvoiceInfo(response.data);
                    setInvoiceData(response.data.operatorData);
                    setSubAdminData(response.data.subadminInvoice);
                    setIsSearchClicked(true);
                }
            }
        } catch (error) {
            console.error('Error fetching invoice data:', error);
            toast.error('Error fetching invoice data: ' + error);

        } finally {
            setLoading1(false);

        }
    };

    const SearchNotCompensate = async () => {
        try {
            setLoading1(true);
            setIsSearchClickedNot(false);
            if (selectedAdmin) {
                const month = selectedMonthTab2 ? selectedMonthTab2.value : null;
                const year = selectedYearTab2 ? selectedYearTab2.value : null;
                const response = await NotCompensateInvoice(selectedAdmin.value, month , year);
                if (!response || response.status === 'error') {
                    const errorMessage = response ? response.errors : 'Error In Fetching Invoice !!!';
                    toast.error(errorMessage);

                } else {
                    setInvoiceInfo(response.data);
                    setInvoiceData(response.data.operatorData);
                    // setSAdminData(response.data.subadminInvoice);
                    setIsSearchClickedNot(true);
                }
            }
        } catch (error) {
            console.error('Error fetching invoice data:', error);
            toast.error('Error fetching invoice data: ' + error);

        } finally {
            setLoading1(false);

        }
    };
    const SearchSingleOpt = async () => {
        try {
            setLoading1(true);
            setIsSingleOptClicked(false);

            if (selectedOperator) {
                const month = selectedMonthTab1 ? selectedMonthTab1.value : null;
                const year = selectedYearTab1 ? selectedYearTab1.value : null;

                const response = await SingleOperatorInvoice(selectedOperator.value, month, year);

                if (!response || response.status === 'error') {
                    const errorMessage = response ? response.errors : 'Error In Fetching Invoice !!!';
                    toast.error(errorMessage);
                } else {
                    setInvoiceInfo(response.data);
                    setInvoiceData(response.data.operatorData);
                    setIsSingleOptClicked(true);
                }
            } else {
                toast.error('Please select an operator.');
            }
        } catch (error: any) {
            console.error('Error fetching invoice data:', error);
            toast.error('Error fetching invoice data: ' + error.message);
        } finally {
            setLoading1(false);
        }
    };


    const fetchData = async () => {
        try {
            const apiResponse = await fetchSubAdmin();
            const response = await fetchOperatorTenants()
            setOperator(response.data)
            setAdmins(apiResponse.data.adminData);

        } catch (error: any) {
            console.error('Error fetching data:', error);
            if (error == 'Request failed with status code 401') {
                navigate('/login');
                toast.error("Token expired. Please login again.")
            }
        }
    };
    const selectedAdminDetails = admins.find(admin => admin.id === selectedAdmin?.value);
    const selectedOperatoretails = operator.find(operators => operators.id === selectedOperator?.value);
    useEffect(() => {

        fetchData();
    }, [])

    const animationNavToggle = (tab: any) => {
        if (animationNavTab !== tab) {
            setanimationNavTab(tab);
        }
    };
    const formatDate = (dateString: string, formatString: string): string => {
        const date = new Date(dateString);
        return format(date, formatString);
      };
    const downloadPDF = () => {
        if (window.innerWidth > 767) {
            const input = document.getElementById('pdf-container');
    
            if (input) {
                html2canvas(input)
                    .then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
                        const pdf = new jsPDF('p', 'mm', 'a4');
                        const imgProps = pdf.getImageProperties(imgData);
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                        pdf.save("download.pdf");
                    });
            }
        } else {
            toast.error('Downloading is not supported on mobile devices.')
        }
    }
    const downloadPDFSingle = () => {
        if (window.innerWidth > 767) {
            const input = document.getElementById('pdf-containersingle');
    
            if (input) {
                html2canvas(input)
                    .then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
                        const pdf = new jsPDF('p', 'mm', 'a4');
                        const imgProps = pdf.getImageProperties(imgData);
                        const pdfWidth = pdf.internal.pageSize.getWidth();
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
                        pdf.save("download.pdf");
                    });
            }
        } else {
            toast.error('Downloading is not supported on mobile devices.')
        }
    };
    


    return (
        <SkeletonTheme baseColor="#c6c6c6" highlightColor="#a39f9f">
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title={t("Invoice")} pageTitle={t("Invoice")} />
                        <div className="d-flex align-items-end justify-content-center mt-3">
                            <Col md={12} lg={12} xxl={9} >
                                <Card>
                                    <CardBody>
                                        <Nav pills className="nav nav-pills animation-nav nav-justified gap-2 mb-3">
                                            <NavItem>
                                                <NavLink style={{ cursor: "pointer" ,whiteSpace:"nowrap" }} className={classnames({ active: animationNavTab === "1", })} onClick={() => { animationNavToggle("1"); setIsSingleOptClicked(false) }} >
                                                   {t('Single Operator Invoice')}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: animationNavTab === "2", })} onClick={() => { animationNavToggle("2"); setIsSearchClicked(false); setIsSearchClickedNot(false) }} >
                                                   {t('SubAdmin Invoice')}
                                                </NavLink>
                                            </NavItem>

                                        </Nav>


                                        <TabContent activeTab={animationNavTab} >
                                            <TabPane tabId="1" id="animation-home">
                                                <Row className="align-items-center border-dark mt-5">
                                                    <Col xl={3}className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                                                        <Select
                                                            value={selectedOperator}
                                                            onChange={(selectedOption: any) => setSelectedOperator(selectedOption)}
                                                            options={operator.map((operators) => ({ value: operators.id, label: `${operators.operatorname} ` }))}
                                                            placeholder={t("Operators")}
                                                            isSearchable={true}
                                                        />


                                                    </Col>
                                                    <Col xl={3}className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                                                        <Select
                                                            value={selectedMonthTab1}
                                                            onChange={(selectedOption: any) => setSelectedMonthTab1(selectedOption)}
                                                            options={months}
                                                            placeholder={t("Select Month")}
                                                            isSearchable={false}
                                                        />
                                                    </Col>
                                                    <Col xl={3}className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                                                        <Select
                                                            value={selectedYearTab1}
                                                            onChange={(selectedOption: any) => setSelectedYearTab1(selectedOption)}
                                                            options={years}
                                                            placeholder={t("Select Year")}
                                                            isSearchable={false}
                                                        />
                                                    </Col>
                                                    <Col xl={3}>
                                                    <h6 className="text-muted mt-2">{t("Select Month or Year to get Invoice")}</h6>
                                                    </Col>
                                                </Row>
                                                <div className="text-end mt-3">
                                                    {loading1 ? (
                                                        <Skeleton width={100} height={40} />
                                                    ) : (
                                                        <div style={{ display: 'flex', gap: '10px', justifyContent: "end" }}>
                                                            <button className="btn btn-primary" onClick={() => { setIsSingleOptClicked(true); SearchSingleOpt() }}>
                                                               {t('Search Single Operator')}
                                                            </button>
                                                            <button className="btn btn-primary ml-5" onClick={() => { downloadPDFSingle() }}>
                                                                {t('Download')}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>

                                                {isSingleOptClicked && (
                                                    <div id="pdf-containersingle" style={{ backgroundColor: "#f3f3f9", borderRadius: "5px", marginTop: "2em" ,overflowX : 'auto'}}>
                                                        <Row>
                                                            <Col lg={12}>

                                                                <div className="invoice-info-middle">
                                                                    <h1>{t('Invoice')}</h1>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12}>

                                                                <div className="invoice-info-right" style={{ fontWeight: "bold" }} >
                                                                    <div>{t('Invoice Number')}: {invoiceInfo.invoiceNumber}</div>
                                                                    <div>{t('Created Date')}: {new Date(invoiceInfo.createdDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</div>
                                                                    <div>{t('Due Date')}: {new Date(invoiceInfo.dueDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</div>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ margin: "0.25em" , color : 'black' }}>
                                                            <Col lg={9} md={8} sm={12} className="jusify-content-start">
                                                                <div style={{ fontWeight: "bold" }} >
                                                                    <div>{t('Address')}: 123 Main St <br />willima for collins above hill area <br /> dripping compound</div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} md={4} sm={12} style={{ display: "flex", justifyContent: "flex-end" ,fontWeight: "bold" ,  }}>

                                                                <div >{t('User Name')}: {selectedOperatoretails?.operatorname} </div>


                                                            </Col>

                                                        </Row>
                                                        <div className="table-responsive">

                                                        <Table striped className="invoice-table">
                                                            <thead>
                                                                <tr className="text-center">
                                                                    <th>{t('Operator Name')}</th>
                                                                    <th>{t('Game Provider')}</th>
                                                                    <th>{t('Brands')}</th>
                                                                    <th>{t('Month')}</th>
                                                                    <th>{t('Total Bet Amount')}</th>
                                                                    <th>{t('Win Amount')}</th>
                                                                    <th>GGR</th>
                                                                    <th>NGR</th>
                                                                    <th>{t('Gross Profit')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {invoiceData && invoiceData.length > 0 ? (
                                                                    <>
                                                                        {invoiceData.map((data, index) => (
                                                                            <tr className="text-center" key={index}>
                                                                                <td>{data.operator_name}</td>
                                                                                <td>{data.game_provider_name}</td>
                                                                                <td>{data.platform} &nbsp;( {data.ggr_percent}%) </td>
                                                                                <td>{formatDate(data.month,'MMMM yyyy')}</td>
                                                                                <td>€ { data.total_bet}</td>
                                                                                <td>€ {data.total_win }</td>
                                                                                <td>€ {data.adjusted_ggr ? data.adjusted_ggr.toFixed(2) : '0.00'}</td>
                                                                                <td>€ {data.ngr_value ? data.ngr_value.toFixed(2) : '0.00'}</td>
                                                                                <td>€ {data.ggr }</td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td colSpan={9} style={{ fontWeight: "bold", fontSize: "18px", textAlign: "end" }}>
                                                                               {t('Total Payable')} = € {invoiceInfo.total ? invoiceInfo.total.toFixed(2) : 0}
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan={9} style={{ textAlign: "center" }}>
                                                                            {t('No records found')}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                        </div>
                                                    </div>
                                                )}

                                            </TabPane>

                                            <TabPane tabId="2" id="animation-profile">
                                                <Row className="align-items-center border-dark mt-5">
                                                    <Col xl={3} className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                                                        <Select
                                                            value={selectedAdmin}
                                                            onChange={(selectedOption: any) => setSelectedAdmin(selectedOption)}
                                                            options={admins.map((admin) => ({ value: admin.id, label: `${admin.first_name} ` }))}
                                                            placeholder={t("Subadmin")}
                                                            isSearchable={true}
                                                        />


                                                    </Col>
                                                    <Col xl={3} className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                                                        <Select
                                                            value={selectedMonthTab2}
                                                            onChange={(selectedOption : any) => setSelectedMonthTab2(selectedOption)}
                                                            options={months}
                                                            placeholder={t("Select Month")}
                                                            isSearchable={false}
                                                        />
                                                    </Col>
                                                    <Col xl={3} className="mt-3 mb-3 mt-xl-0 mb-xl-0">
                                                        <Select
                                                            value={selectedYearTab2}
                                                            onChange={(selectedOption : any) => setSelectedYearTab2(selectedOption)}
                                                            options={years}
                                                            placeholder={t("Select Year")}
                                                            isSearchable={false}
                                                        />
                                                    </Col>
                                                    <Col xl={3}>
                                                    <h6 className="text-muted mt-2">{t('Select Month or Year to get Invoice')}</h6>
                                                    </Col>
                                                </Row>
                                                <div className="text-end mt-3">
                                                    {loading1 ? (
                                                        <Skeleton width={100} height={40} />
                                                    ) : (
                                                        <div style={{ display: 'flex', gap: '10px', justifyContent: "end" }}>
                                                            <button className="btn btn-primary" onClick={() => { setIsSearchClicked(true); SearchCompensate(); setIsSearchClickedNot(false) }}>
                                                               {t('Search Compensate')}
                                                            </button>
                                                            <button className="btn btn-primary" onClick={() => { setIsSearchClickedNot(true); SearchNotCompensate(); setIsSearchClicked(false); }}  >
                                                                {t('Search No Compensate')}
                                                            </button>
                                                            <button className="btn btn-primary" onClick={() => { downloadPDF(); }}  >
                                                                {t('Download')}
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                                {isSearchClicked && (
                                                    <div id="pdf-container" style={{ backgroundColor: "#f3f3f9", borderRadius: "5px", marginTop: "2em" }}>
                                                        <Row>
                                                            <Col lg={12}>

                                                                <div className="invoice-info-middle">
                                                                    <h1>{t('Invoice')}</h1>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12}>

                                                                <div className="invoice-info-right" style={{ fontWeight: "bold" }} >
                                                                    <div>{t('Invoice Number')}: {invoiceInfo.invoiceNumber}</div>
                                                                    <div>{t('Created Date')}: {new Date(invoiceInfo.createdDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</div>
                                                                    <div>{t('Due Date')}: {new Date(invoiceInfo.dueDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</div>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ margin: "0.25em" , color : 'black' }}>
                                                            <Col lg={9} className="jusify-content-start">
                                                                <div style={{ fontWeight: "bold" }} >
                                                                    <div>{t('Address')}: 123 Main St <br />willima for collins above hill area <br /> dripping compound</div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} style={{ display: "flex", justifyContent: "flex-end" ,fontWeight: "bold" ,  }}>

                                                                <div >{t('User Name')}: {selectedOperatoretails?.operatorname} </div>


                                                            </Col>

                                                        </Row>
                                                        <div className="table-responsive">
                                                        <Table striped className="invoice-table mt-5">
                                                            <thead>
                                                                <tr className="text-center">
                                                                    <th>{t('Game Provider')}</th>
                                                                    <th>{t('Brands')}</th>
                                                                    <th>{t('Month')}</th>
                                                                    <th>{t('Total Bet Amount')}</th>
                                                                    <th>{t('Win Amount')}</th>
                                                                    <th>GGR</th>
                                                                    <th>NGR</th>
                                                                    <th>{t('Gross Profit')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {subAdminData && subAdminData.length > 0 ? (
                                                                    <>
                                                                        {subAdminData.map((data, index) => (
                                                                            <tr className="text-center" key={index}>
                                                                                <td>{data.game_provider_name}</td>
                                                                                <td>{data.platform} &nbsp;( {data.ggr_percent}%) </td>
                                                                                <td>{formatDate(data.month,'MMMM yyyy')}</td>
                                                                                <td>€ {data.total_bet}</td>
                                                                                <td>€ {data.total_win}</td>
                                                                                <td>€ {data.adjusted_ggr ? data.adjusted_ggr.toFixed(2) : '0.00'}</td>
                                                                                <td>€ {data.ngr_value ? data.ngr_value.toFixed(2) : '0.00'}</td>
                                                                                <td>€ {parseFloat(data.ggr).toFixed(2)}</td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td colSpan={8} style={{ fontWeight: "bold", fontSize: "18px", textAlign: "end" }}>
                                                                                {t('Total Payable')} = € {subAdminData.reduce((total, data) => total + data.adjusted_ggr, 0).toFixed(2)}
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan={8} style={{ textAlign: "center" }}>
                                                                            {t('No records found')}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                        </div>
                                                    </div>
                                                )}
                                                {isSearchClickedNot && (
                                                    <div id="pdf-container" style={{ backgroundColor: "#f3f3f9", borderRadius: "5px", marginTop: "2em" }}>
                                                        <Row>
                                                            <Col lg={12}>

                                                                <div className="invoice-info-middle">
                                                                    <h1>{t('Invoice')}</h1>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={12}>

                                                                <div className="invoice-info-right" style={{ fontWeight: "bold" }} >
                                                                    <div>{t('Invoice Number')}: {invoiceInfo.invoiceNumber}</div>
                                                                    <div>{t('Created Date')}: {new Date(invoiceInfo.createdDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</div>
                                                                    <div>{t('Due Date')}: {new Date(invoiceInfo.dueDate).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })}</div>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ margin: "0.25em" , color : 'black' }}>
                                                            <Col lg={9} className="jusify-content-start">
                                                                <div style={{ fontWeight: "bold" }} >
                                                                    <div>{t('Address')}: 123 Main St <br />willima for collins above hill area <br /> dripping compound</div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3} style={{ display: "flex", justifyContent: "flex-end" ,fontWeight: "bold" ,  }}>

                                                                <div >{t('User Name')}: {selectedOperatoretails?.operatorname} </div>


                                                            </Col>

                                                        </Row>
                                                        <div className="table-responsive">
                                                        <Table striped className="invoice-table">
                                                            <thead>
                                                                <tr className="text-center">
                                                                    <th>{t('Operator Name')}</th>
                                                                    <th>{t('Game Provider')}</th>
                                                                    <th>{t('Brands')}</th>
                                                                    <th>{t('Month')}</th>
                                                                    <th>{t('Total Bet Amount')}</th>
                                                                    <th>{t('Win Amount')}</th>
                                                                    <th>GGR</th>
                                                                    <th>NGR</th>
                                                                    <th>{t('Gross Profit')}</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {invoiceData && invoiceData.length > 0 ? (
                                                                    <>
                                                                        {invoiceData.map((data, index) => (
                                                                            <tr className="text-center" key={index}>
                                                                                <td>{data.operator_name}</td>
                                                                                <td>{data.game_provider_name}</td>
                                                                                <td>{data.platform} &nbsp;( {data.ggr_percent}%)</td>
                                                                                <td>{formatDate(data.month,'MMMM yyyy')}</td>
                                                                                <td>€ {data.total_bet ? data.total_bet.toFixed(2) : '0.00'}</td>
                                                                                <td>€ {data.total_win ? data.total_win.toFixed(2) : '0.00'}</td>
                                                                                <td>€ {data.adjusted_ggr ? data.adjusted_ggr.toFixed(2) : '0.00'}</td>
                                                                                <td>€ {data.ngr_value ? data.ngr_value.toFixed(2) : '0.00'}</td>
                                                                                <td>€ {data.ggr ? data.ggr.toFixed(2) : "0.00"}</td>
                                                                            </tr>
                                                                        ))}
                                                                        <tr>
                                                                            <td colSpan={9} style={{ fontWeight: "bold", fontSize: "18px", textAlign: "end" }}>
                                                                                {t('Total Payable')} = € {invoiceInfo.total ? invoiceInfo.total.toFixed(2) : 0}
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                ) : (
                                                                    <tr>
                                                                        <td colSpan={9} style={{ textAlign: "center" }}>
                                                                            {t('No records found')}
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                        </div>
                                                    </div>
                                                )}


                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>

                        </div>

                    </Container>
                </div>
            </React.Fragment>

        </SkeletonTheme>
    );
};

export default Invoice;
